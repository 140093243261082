import {ConditionClassMap, OperatorType} from "./ConditionClassMap";
import {delayDebounced} from "../../../utils/Utils";
import {evaluateTemplate} from "../../TemplateEvaluation";
import {NOOP_FUNCTION, toArray} from "../../../../stem-core/src/base/Utils.js";
import {userDataModule} from "../../UserDataModule.js";
import {JourneyActionRunner} from "./JourneyActionRunner.js";
import {merchantFunctionModule} from "../../merchant-state/MerchantFunctionModule.js";


/** @return {Condition} */
export function parseConditionNode(journey, node, requestReEval) {
    // Array is implicitly treated as an "and" operator.
    if (Array.isArray(node)) {
        node = {type: OperatorType.AND, ops: node};
    }

    // A node with the exclude key set is negated.
    if (node.exclude) {
        node = {type: OperatorType.NOT, op: {...node, exclude: undefined}};
    }

    // A string node is considered a condition of that type.
    if (typeof node === "string" || node instanceof String) {
        node = {type: node};
    }

    const conditionClass = ConditionClassMap[node.type];
    if (!conditionClass) {
        throw `Unknown condition type: ${node.type}`;
    }
    const condition = new conditionClass(journey, node, requestReEval);
    if (node.type === OperatorType.AND || node.type === OperatorType.OR) {
        condition.ops = node.ops.map(subCondition => parseConditionNode(journey, subCondition, requestReEval));
    }
    if (node.type === OperatorType.NOT) {
        condition.ops = [parseConditionNode(journey, node.op, requestReEval)];
    }
    return condition;
}


/*
UserJourneyDescription = {
    id: Number,
    alias: String,
    // The precondition needs to be true in order for the journey to be possible when selecting by weight
    precondition: UJNodeDescription | undefined,
    // Pick the largest priority, and on a tie use the weight
    // TODO move into the entry
    priority: number | undefined,
    weight: number | undefined,

    // Now see if we actually should run it
    condition: UJNodeDescription | undefined,
    throttle: UserJourneyThrottle | undefined,

    action: UserJourneyAction,
}
*/

// Will evaluate the journey condition now and whenever needed and will call the runner
export class JourneyConditionEvaluator {
    constructor(journey) {
        this.journey = journey;
        this.runner = new JourneyActionRunner(journey);
        const evaluateDebounced = delayDebounced(() => this.evaluate(), 0); // TODO @branch this shouldn't fail just because this needs to be async

        // TODO @cleanup remove duplicate code
        const evaluatedConditionOptions = evaluateTemplate(journey.options.condition || "true");
        this.condition = parseConditionNode(journey, evaluatedConditionOptions, evaluateDebounced);
        this.evaluate();

        for (const eventType of toArray(journey.options?.reeval)) {
            if (eventType === "auth") {
                // TODO Should be attach listener
                userDataModule.authenticationChange.addListener(() => {
                    // TODO This is really complicated because that fucking templating is only evaluated once and should be dynamic
                    this.condition?.cleanup();
                    const evaluatedConditionOptions = evaluateTemplate(journey.options.condition || "true");
                    this.condition = parseConditionNode(journey, evaluatedConditionOptions, evaluateDebounced);
                    this.evaluate();
                });
            } else {
                console.warn("Unknown reeval condition", eventType);
            }
        }
    }

    evaluate() {
        const result = this.condition.evaluate();
        if (result && this.haveEvaluatedOnce) {
            console.debug("Running again", this.journey);
        }
        if (result) {
            this.runner.runAction();
        }
        this.haveEvaluatedOnce = true;
    }

    cleanup() {
        this.condition.cleanup();
    }
}

export function evaluateJourneyPrecondition(journey) {
    const {precondition} = journey.options;
    if (!precondition) {
        return true;
    }
    const condition = parseConditionNode(journey, precondition, NOOP_FUNCTION);
    const result = condition.evaluate();
    condition.cleanup();
    if (result == null) {
        merchantFunctionModule.handleException(
            new Error(`Precondition for journey '${journey.alias}' could not be evaluated synchronously.`),
            journey.alias,
        );
        return false;
    }
    return result;
}

export function evaluateConditionAndRun(journey) {
    // TODO @branch I'm not sure running the journey's action every time the condition
    //  re-evaluates to true is correct in all cases, think about this some more.
    // TODO @branch @Mihai by default journeys should have a single blocker
    return new JourneyConditionEvaluator(journey);
}
