import {Condition} from "../engine/Condition";
import {isString, toArray} from "../../../../stem-core/src/base/Utils";
import {merchantFunctionModule} from "../../merchant-state/MerchantFunctionModule";
import {loadReferrerUrl} from "../../../utils/Referrer";
import {isPathEqual} from "../../../utils/PageAccessHelpers.js";


export function testPath(path, include, exclude, oneOf, filterFunction) {
    if (!path || !isString(path)) {
        return false;
    }
    const includeRE = toArray(include || ".*").map(str => RegExp(`^${str}$`));
    const excludeRE = toArray(exclude).map(str => RegExp(`^${str}$`));
    if (excludeRE.find(re => re.test(path) || re.test(path + "/")) != null) {
        return false;
    }
    if (includeRE.find(re => re.test(path) || re.test(path + "/")) == null) {
        return false;
    }
    if (oneOf != null) {
        oneOf = toArray(oneOf);
        if (!oneOf.find((targetPath) => targetPath && isPathEqual(targetPath, path))) {
            return false;
        }
    }
    if (filterFunction != null) {
        const [success, result] = merchantFunctionModule.call(filterFunction, path);
        if (!success || !result) {
            return false;
        }
    }
    return true;
}


/** @property {{
 *      includePattern: string|string[]|undefined,
 *      excludePattern: string|string[]|undefined,
 *      oneOf: string|string[]|undefined,
 *      filterFunction: string|Array|undefined,
 *  }} options */
export class PathCondition extends Condition {
    evaluate() {
        return testPath(window.location.pathname, this.options.includePattern, this.options.excludePattern, this.options.oneOf, this.options.filterFunction);
    }
}

/** @property {{
 *      includePattern: string|string[]|undefined,
 *      excludePattern: string|string[]|undefined,
 *      oneOf: string|string[]|undefined,
 *      filterFunction: string|Array|undefined,
 *  }} options */
export class ReferrerCondition extends Condition {
    evaluate() {
        return testPath(loadReferrerUrl(), this.options.includePattern, this.options.excludePattern, this.options.oneOf, this.options.filterFunction);
    }
}
