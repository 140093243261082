import {IFrameMessages, formatType} from "./IFrameMessages";
import {iframeToSdkChannel} from "./IframeToSdkChannel";
import {IFRAME_APP_TYPE} from "../Constants";
import {OnceDispatcher} from "../../stem-core/src/base/Dispatcher.js";


export const widgetLoadedDispatcher = new OnceDispatcher();

export function makeSDKToIFrameChannel(iFrame, appType) {
    let pendingMessages = [];
    const sendPendingMessages = () => {
        // TODO: What happens with all the pending messages if no new message that empties the queue is sent?
        //  Ideally we would like to have a callback as soon as the contentWindow because available again
        //  in order to send all those messages
        if (iFrame.getAttribute("htmlLoaded") && iFrame.contentWindow) {
            pendingMessages.forEach(message => iFrame.contentWindow.postMessage(message, "*"));
            pendingMessages = [];
        }
    };
    if (!iFrame.getAttribute("htmlLoaded")) {
        // TODO @branch shouldn't this just be an addListenerOnce?
        const listener = iframeToSdkChannel.addListener(IFrameMessages.IFRAME_HTML_LOADED, ({version}, sourceIframe) => {
            if (sourceIframe === iFrame) {
                iFrame.setAttribute("htmlLoaded", true);
                if (appType === IFRAME_APP_TYPE.widget) {
                    widgetLoadedDispatcher.dispatch(version);
                }
                sendPendingMessages();
                listener.remove();
            }
        });
    }

    return (type, data = {}) => {
        data.type = formatType(type);
        data.appType = appType;
        pendingMessages.push(data);
        sendPendingMessages();
    };
}
