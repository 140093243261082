import {Condition} from "../engine/Condition";
import {merchantFunctionModule} from "../../merchant-state/MerchantFunctionModule";


export class FunctionCondition extends Condition {
    havePendingResult = false;

    constructor(...args) {
        super(...args);

        if (this.options.event != null) {
            this.attachEventListener(window, this.options.event, this.requestReEval);
        }
        if (this.options.interval != null) {
            this.attachInterval(this.requestReEval, this.options.interval);
        }
    }

    evaluateResult(result) {
        if (this.options.hasOwnProperty("equal")) {
            return result === this.options.equal;
        }
        return !!result;
    }

    resolvePromiseResult(result) {
        this.havePendingResult = false;
        this.cachedResult = result;
        this.requestReEval();
    }

    evaluate() {
        if (this.cachedResult != null) {
            return this.evaluateResult(this.cachedResult);
        }

        // Don't start a new call if there's a pending promise
        if (this.havePendingResult) {
            return null;
        }
        const [success, result] = merchantFunctionModule.call(this.options.function);

        if (!success) {
            // Something failed in finding/executing the merchant's function.
            return null;
        }

        if (result instanceof Promise) {
            this.cachedResult = null;
            this.havePendingResult = true;
            result.then((result) => this.resolvePromiseResult(result), () => this.resolvePromiseResult(null));
            return null;
        }

        return this.evaluateResult(result);
    }
}
