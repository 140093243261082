import {GateElement, GateStyle} from "../elements/GateElement";


const PopupGateStyle = {
    gate: {
        ...GateStyle.gate,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        position: "fixed",
        background: "rgba(154, 151, 151, 0.88)"
    },
};

export class PopupGate extends GateElement {
    styleSheet = PopupGateStyle;

    computeParentStyle() {
        return {
            overflow: "hidden",
            paddingRight: "15px"
        };
    }
}
