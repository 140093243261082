import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {ensure} from "../../../stem-core/src/base/Require.js";
import {IFrameMessages} from "../../../blink-sdk/messaging/IFrameMessages.js";
import {iFrameConnection} from "../../services/IFrameConnection.js";
import {makeSDKToIFrameChannel} from "../../../blink-sdk/messaging/SdkToIFrameChannel.js";

const AMAZON_PAY_JS_SANDBOX = "https://static-na.payments-amazon.com/OffAmazonPayments/us/sandbox/js/Widgets.js";
const AMAZON_PAY_JS_PROD = "https://static-na.payments-amazon.com/OffAmazonPayments/us/js/Widgets.js";

const AMAZON_LOGIN_OPTIONS = {
    popup: "true",
    scope: "profile postal_code payments:widget payments:shipping_address"
}


// We need this global instance because the AmazonPay widgets won't reset their callbacks properly sometimes.
let instance = null;


export class AmazonPayWidget extends UI.Element {
    static addedSDKListeners = false;
    userData = null;

    // -- Called from SDK
    static show(options) {
        const {publicConfig} = options.amazonPaymentProcessor;

        if (this.isReady) {
            this.insertModal(options);
            return;
        }

        window.onAmazonLoginReady = () => {
            amazon.Login.setClientId(publicConfig.clientId);
            amazon.Login.setSandboxMode(publicConfig.sandbox);
            amazon.Login.setMerchantID(publicConfig.merchantId);
            this.isReady = true;
            this.insertModal(options);
        };

        const JS_ENDPOINT = publicConfig.sandbox ? AMAZON_PAY_JS_SANDBOX : AMAZON_PAY_JS_PROD;

        ensure(JS_ENDPOINT);
    }

    static insertModal(options) {
        const showModal = () => this.create(document.body, options);
        const authHandler = amazon.Login.authorize(AMAZON_LOGIN_OPTIONS, showModal);
    }

    // -- Called from Iframe
    static requestWidgetInSDK(amazonPaymentProcessor, finishCallback) {
        this.finishCallback = finishCallback;

        // Serialize for the SDK
        amazonPaymentProcessor = amazonPaymentProcessor.toSDK();
        iFrameConnection.sendToSDK(IFrameMessages.REQUEST_AMAZON_PAY, {amazonPaymentProcessor});

        // Listen in the iframe for events triggered from
        if (this.addedSDKListeners) {
            return;
        }
        this.addedSDKListeners = true;
        // TODO @cleanup I guess here we'd want something like setListener
        iFrameConnection.addListener(IFrameMessages.AMAZON_PAY_EVENT, (payload) => {
            this.finishCallback(payload.orderReferenceId);
            this.finishCallback = null; // TODO @branch ???
        });
    }

    extraNodeAttributes(attr) {
        attr.setStyle({
            zIndex: 99999909 + 1,
            height: "100%",
            width: "100%",
            position: "fixed",
            background: "gray",
            top: 0,
            left: 0,
            opacity: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        });
    }

    getAmazonConfig() {
        return this.options.amazonPaymentProcessor.publicConfig;
    }

    getAmazonSellerId() {
        return this.getAmazonConfig().merchantId;
    }

    updateRecurringPaymentConsent(billingAgreementConsentStatus) {
        const consentStatus = (billingAgreementConsentStatus.getConsentStatus() == "true"); // We're actually getting "true" as string from Amazon, fuck
        this.okButton.updateOptions({disabled: !consentStatus});
    }

    finishFlow(withPaymentMethod) {
        if (withPaymentMethod && this.orderReferenceId) {
            const {iframeElement} = this.options;
            const payload = {
                orderReferenceId: this.orderReferenceId,
            };

            // TODO @branch this is unclean
            const sendMessage = makeSDKToIFrameChannel(iframeElement, "panel");
            sendMessage(IFrameMessages.AMAZON_PAY_EVENT, payload);
        }
        this.destroyNode();
    }

    render() {
        const buttonStyle = {
            fontFamily: "sans-serif",
            fontSize: 14,
            height: 40,
            width: 120,
            borderRadius: 4,
            cursor: "pointer",
            margin: "0px 8px",
        }

        const centerElementStyle = {
            width: "100%",
            maxWidth: 375,
            boxSizing: "border-box",
            background: "white",
            padding: 20,
        }

        return <div style={centerElementStyle}>
            <div id="walletWidgetDiv" style={{height: 228, width: "100%", marginBottom: 16}} />
            <div id="consentWidgetDiv" style={{height: 140, width: "100%", margin: "16px 0px"}}/>
            <div style={{marginTop: 16, textAlign: "center"}}>
                <button style={buttonStyle} onClick={() => this.finishFlow()}>Cancel</button>
                <button style={buttonStyle} ref="okButton" disabled onClick={() => this.finishFlow(true)}>OK</button>
            </div>
        </div>;
    }

    async onAmazonAuth(authHandler) {
        // const endpointURL = AMAZON_PAY_TOKEN_URL + authHandler.access_token;
        // const rawResponse = await fetch(endpointURL);
        // this.userData = await rawResponse.json();
        // console.warn("Got data:", this.userData);
        // window.at = authHandler.access_token;
        this.showPaymentWidget();
    }

    // Method disabled for now.
    showAddressWidget() {
        new OffAmazonPayments.Widgets.AddressBook({
            sellerId: this.getAmazonSellerId(),
            agreementType: "BillingAgreement",

            onReady: (billingAgreement) => {
                console.warn("Got billing agreement:", billingAgreement.getAmazonBillingAgreementId(), billingAgreement, performance.now());
            },

            onAddressSelect: (billingAgreement) => {
                // Replace the following code with the action that you want to perform
                // after the address is selected.
                // The amazonBillingAgreementId can be used to retrieve
                // the address details by calling the GetBillingAgreementDetails operation.
                // If rendering the AddressBook and Wallet widgets on the same page, you
                // should wait for this event before you render the Wallet widget for
                // the first time.
                // The Wallet widget re-renders itself on all subsequent
                // onAddressSelect events without any action from you. We don't
                // recommend that you explicitly refresh it.
                console.warn("Address select:", billingAgreement, performance.now());
            },
            design: {
                designMode: "responsive"
            },
            onError: (error) => {
                console.error("Amazon address widget error:", error.getErrorCode(), error.getErrorMessage());
            }
        }).bind("addressBookWidgetDiv");
    }

    showPaymentWidget() {
        new OffAmazonPayments.Widgets.Wallet({
            sellerId: this.getAmazonSellerId(),
            design: {
                designMode: "responsive"
            },
            agreementType: "BillingAgreement",
            onReady: (billingAgreement) => {
                instance.billingAgreementId = billingAgreement.getAmazonBillingAgreementId();
                console.warn("Billing agreement", instance.billingAgreementId, billingAgreement);
            },
            // Add the onOrderReferenceCreate function to
            // generate an Order Reference ID.
            onOrderReferenceCreate: (orderReference) => {
                // Use the following cod to get the generated Order Reference ID.
                instance.orderReferenceId = orderReference.getAmazonOrderReferenceId();
                console.log("Order ref", instance.orderReferenceId, orderReference);
            },
            onPaymentSelect: (billingAgreement) => {
                console.warn("Payment selected: ", this.orderReferenceId, billingAgreement);
                instance.showRecurringPaymentConsentWidget();
            },

            onError: (error) => {
                console.error("Amazon payment widget error:", error.getErrorCode(), error.getErrorMessage());
            }
        }).bind("walletWidgetDiv");
    }

    showRecurringPaymentConsentWidget() {
        // TODO @branch protect against multiple calls?
        new OffAmazonPayments.Widgets.Consent({
            sellerId: this.getAmazonSellerId(),
            amazonBillingAgreementId: this.orderReferenceId,
            design: {
                designMode: "responsive"
            },
            onReady: (billingAgreementConsentStatus) => {
                // Called after widget renders
                instance.updateRecurringPaymentConsent(billingAgreementConsentStatus);
            },
            onConsent: (billingAgreementConsentStatus) => {
                // Called when the user clicks on the checkbox
                instance.updateRecurringPaymentConsent(billingAgreementConsentStatus);
            },
            onError: (error) => {
                console.error("Amazon payment widget error:", error.getErrorCode(), error.getErrorMessage());
            }
        }).bind("consentWidgetDiv");
    }

    onMount() {
        instance = this;
        this.showPaymentWidget();
    }
}
