export const PAGE_LOAD_TIMING_MARKS = {
    sdkJS: "sdkJS",
    publicData: "publicData",
    iFrameJS: "iFrameJS",
    sdkInit: "sdkInit",
};

const pageLoadTimings = {};

export function markPageLoadTiming(key) {
    pageLoadTimings[key] = performance.now() | 0; // Convert to int
}

export function getPageLoadTimings() {
    return pageLoadTimings;
}
