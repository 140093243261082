import {merchantLocalStorage} from "../modules/merchant-state/MerchantStorage.js";
import {MERCHANT_STORAGE_KEYS} from "../Constants.js";
import {Dispatcher} from "../../stem-core/src/base/Dispatcher.js";
import {isString} from "../../stem-core/src/base/Utils.js";


const PAGE_VISITS_MAX_ENTRIES = 50;
let lastPageAccessed = null;
const pageAccessDispatcher = new Dispatcher();

export function normalizePath(url) {
    if (isString(url)) {
        url = new URL(url, self.location.href);
    }
    // TODO This is from Darius, why do we care about normalizetion
    return url.pathname + (url.pathname.endsWith("/") ? "" : "/");
}

export function isPathEqual(a, b) {
    return normalizePath(a) === normalizePath(b);
}

export function loadPageVisitsMap() {
    const initialState = merchantLocalStorage.getItem(MERCHANT_STORAGE_KEYS.pageVisits);
    return new Map(initialState?.v || []);
}

export function updatePageVisit(url, metadata) {
    let pageVisitsMap = loadPageVisitsMap();
    const path = normalizePath(url);

    const now = Date.now();
    const currentVisit = {
        firstVisit: now, // To be overwritten if exists
        ...pageVisitsMap.get(path),
        ...metadata,
        lastVisit: now,
    };

    // Add to the set and maybe clean it if there are too many entries
    pageVisitsMap.set(path, currentVisit);

    // Truncate if there are too many.
    if (pageVisitsMap.size > PAGE_VISITS_MAX_ENTRIES) {
        const newEntries = [...pageVisitsMap]
            .sort((a, b) => b[1].lastVisit - a[1].lastVisit) // sort descending by timestamp
            .slice(0, PAGE_VISITS_MAX_ENTRIES); // keep the most recent

        pageVisitsMap = new Map(newEntries);
    }
    merchantLocalStorage.setItem(MERCHANT_STORAGE_KEYS.pageVisits, {
        v: [...pageVisitsMap],
    });
    return [currentVisit, pageVisitsMap];
}

export function isURLViewed(url) {
    const visitedPages = [
        location.pathname,
        ...loadPageVisitsMap().keys()
    ]

    for (const visitedPath of visitedPages) {
        if (isPathEqual(url, visitedPath)) {
            return true;
        }
    }
    return false;
}

// Will immediately call the function with the current URL
export function onCurrentAndFuturePageAccess(callback) {
    if (pageAccessDispatcher.listeners.length === 0) {
        lastPageAccessed = self.location.href;
        setInterval(() => {
            const currentUrl = self.location.href;
            if (currentUrl !== lastPageAccessed) {
                lastPageAccessed = currentUrl;
                pageAccessDispatcher.dispatch(currentUrl);
            }
        }, 200);
    }
    callback(lastPageAccessed);
    return pageAccessDispatcher.addListener(callback);
}

export function dispatchPageAccess(url) {
    pageAccessDispatcher.dispatch(url);
}

export function markURLVisited(url, metadata) {
    updatePageVisit(url, metadata);
    dispatchPageAccess(url);
}
