import {NodeElement} from "../elements/NodeElement";
import {
    WALLET_COLLAPSED_WIDTH,
    WIDGET_MAX_HEIGHT,
    WIDGET_EXPANDED_DESKTOP_BOTTOM_OFFSET
} from "../../../blinkpay/widget/WidgetConstants";
import {IFRAME_TEST_ID, DEFAULT_TRANSITION_DURATION_MS, WIDGET_BACKGROUND, DEFAULT_TRANSITION} from "../../Constants";
import {isSmallScreen} from "../../../blinkpay/UtilsLib";


const WidgetCloseStyle = {
    close: {
        background: WIDGET_BACKGROUND,
        width: WALLET_COLLAPSED_WIDTH + "px",
        height: WALLET_COLLAPSED_WIDTH + "px",
        borderRadius: "100%",
        position: "absolute",
        zIndex: 5,
        overflow: "hidden",
        transition: DEFAULT_TRANSITION,
        outline: 0,
        cursor: "pointer",
        border: "none",
        "-webkit-user-select": "none",
        userSelect: "none",
        boxShadow: "none",
        textShadow: "none",
    },
    collapsed: {
        opacity: 0,
        transform: "scale(0)",
    },
    expanded: {
        opacity: 1,
        transform: "scale(1)",
    },
};

export class WidgetClose extends NodeElement {
    pointerEventsTimeout = null;

    constructor(...args) {
        super(...args);
        this.node.innerHTML = `<svg width="18px" style="margin:auto" version="1.1" viewBox="0 0 16 16"><g fill="none" fill-rule="evenodd"><g transform="translate(-1221 -791)" fill="#fff" fill-rule="nonzero"><g transform="translate(896 395)"><g transform="translate(306 377)"><g transform="translate(14.5 14.5)"><polygon points="19.792 6.6771 18.323 5.2083 12.5 11.031 6.6771 5.2083 5.2083 6.6771 11.031 12.5 5.2083 18.323 6.6771 19.792 12.5 13.969 18.323 19.792 19.792 18.323 13.969 12.5"/></g></g></g></g></g></svg>`;
    }

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: IFRAME_TEST_ID.widgetCloseButton,
            expanded: false,
            positionBottom: 0,
            positionRight: 0,
            activePanel: false,
            visible: false,
        };
    }

    refreshStyle() {
        super.refreshStyle();
        this.setStyle(WidgetCloseStyle.close);
        const widgetExpandedSize = this.options.positionBottom + WIDGET_MAX_HEIGHT + WIDGET_EXPANDED_DESKTOP_BOTTOM_OFFSET;
        this.setStyle({
            display: (isSmallScreen() || widgetExpandedSize > document.documentElement.clientHeight) ? "none" : "flex",
            bottom: this.options.positionBottom + "px",
            right: this.options.positionRight + "px",
        });
        clearTimeout(this.pointerEventsTimeout);
        if (this.options.expanded && !this.options.activePanel && this.options.visible) {
            this.setStyle(WidgetCloseStyle.expanded);
            this.pointerEventsTimeout = this.attachTimeout(() => {
                this.setStyle("pointerEvents", "all");
            }, DEFAULT_TRANSITION_DURATION_MS);
        } else {
            this.setStyle(WidgetCloseStyle.collapsed);
            this.setStyle("pointerEvents", "none");
            // remove the focus from the button
            // this hides the unwanted dotted outline on firefox mobile
            this.node.blur();
        }
    }
}
