import {Condition} from "../engine/Condition";


/** @property {{
 *      device: string|string[]|undefined,
 *  }} options */
export class DeviceCondition extends Condition {
    attachWindowResizeListener() {
        if (this.resizeHandler) {
            return;
        }
        this.resizeHandler = this.attachEventListener(window, "resize", this.requestReEval);
    }

    evaluate() {
        let result = true;
        // TODO Implement Device, OS, Browser, etc
        const {screenWidth, screenHeight, os, browser} = this.options;

        const checkValueInRange = (value, range) => {
            if (!range) {
                return;
            }
            const [minValue, maxValue] = range;
            result &&= !minValue || (minValue <= value);
            result &&= !maxValue || (value <= maxValue);
        }

        checkValueInRange(window.screen.width, screenWidth);
        checkValueInRange(window.screen.height, screenHeight);

        if (screenWidth || screenHeight) {
            this.attachWindowResizeListener();
        }

        return result;
    }
}
