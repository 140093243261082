import {GateElement, GateStyle} from "../elements/GateElement";
import {GATE_COVER_PANEL_POSITION} from "../../Constants";


const CoverGateStyle = {
    gate: {
        ...GateStyle.gate,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        position: "absolute",
    },
    panelBasicStyle: {
        position: "absolute",
    },
    panelStyle: {
        [GATE_COVER_PANEL_POSITION.top]: {
            top: 0,
            bottom: "auto",
        },
        [GATE_COVER_PANEL_POSITION.center]: {
            margin: "auto",
            top: 0,
            bottom: 0,
        },
        [GATE_COVER_PANEL_POSITION.bottom]: {
            top: "auto",
            bottom: 0,
        },
    },
};

export class CoverGate extends GateElement {
    styleSheet = CoverGateStyle;

    getPanelStyle() {
        return {
            ...super.getPanelStyle(),
            ...this.styleSheet.panelBasicStyle,
            ...this.styleSheet.panelStyle[this.options.coverPanelPosition],
        };
    }

    refreshStyle() {
        super.refreshStyle();
        this.setStyle("background", this.options.coverColor);
    }
}
