/** @param {UserJourneyDescription[]} journeyEntrypoint
 * @return UserJourneyDescription */
export function selectRandomByWeight(journeyEntrypoint) {
    const largestPriority = journeyEntrypoint.reduce((s, journey) => Math.max(s, journey.priority || 1), 1);
    journeyEntrypoint = journeyEntrypoint.filter(journey => (journey.priority || 1) === largestPriority);

    const weightSum = journeyEntrypoint.reduce((s, journey) => s + (journey.weight || 1), 0);
    const weightedSelection = Math.random() * weightSum;
    let currentWeightSum = 0;
    for (const journey of journeyEntrypoint) {
        currentWeightSum += (journey.weight || 1);
        if (weightedSelection < currentWeightSum) {
            return journey;
        }
    }
    // Just for safety
    return journeyEntrypoint[0];
}
