import {WidgetContainer} from "../ui/widget/WidgetContainer";
import {userDataModule} from "./UserDataModule";
import {sdkState} from "./SDKState";
import {iframeToSdkChannel} from "../messaging/IframeToSdkChannel";
import {IFrameMessages} from "../messaging/IFrameMessages";
import {createPulseAnimation} from "../ui/widget/WalletAnimation";
import {WidgetRoutes} from "../../blinkpay/widget/WidgetRoutes";
import {removeScrollOnScrim} from "../utils/ScrimHelpers.js";
import {IFRAME_APP_TYPE, PANEL_TYPE, DEFAULT_TRANSITION_DURATION_MS} from "../Constants";
import {AmazonPayWidget} from "../../blinkpay/common/payment-methods/AmazonPayWidget.jsx";
import {PanelElement} from "../ui/elements/PanelElement.js";


class WalletModule {
    scrim = null;

    panelDestroyTimeout = null;
    panelDestroyFunction = null;
    pendingChangeRoutes = [];
    currentRoute = WidgetRoutes.INDEX_COLLAPSED;
    pulseAnimation;
    activePanelHandler;

    constructor() {
        this.widgetContainer = new WidgetContainer();
    }

    init() {
        this.widgetContainer.mountOnBody();
        this.widgetContainer.widgetButton.node.addEventListener("click", () => this.changeRoute(WidgetRoutes.INDEX_EXPANDED));
        this.widgetContainer.widgetClose.node.addEventListener("click", () => this.changeRoute(WidgetRoutes.INDEX_COLLAPSED));

        iframeToSdkChannel.addListener(IFrameMessages.ROUTE_CHANGE, (event, iFrame) => {
            if (iFrame === this.widget.node) {
                this.onRouteChange(event);
            }
        });
        // Expand to autopay section.
        iframeToSdkChannel.addListener(IFrameMessages.SHOW_AUTOPAY, () => {
            this.changeRoute(WidgetRoutes.AUTOPAY);
        });

        iframeToSdkChannel.addListener(IFrameMessages.SESSION_PAYMENT_MADE, () => {
            if (!sdkState.hideWallet && !this.isExpanded() && !this.isPopupFlowActive()) {
                this.pulseAnimation = createPulseAnimation(this.widgetContainer.widgetButton.node);
            }
        });

        iframeToSdkChannel.addListener(IFrameMessages.REQUEST_AMAZON_PAY, (options, iframeElement) => {
            AmazonPayWidget.show({
                ...options,
                iframeElement,
            });
        })

        userDataModule.userPreferencesChange.addListener(() => this.widgetContainer.updateOptions({
            showBalance: !!userDataModule.userData.alwaysShowBalance,
        }));
    }

    get widget() {
        return this.widgetContainer.widget;
    }

    renderWallet(widgetPositionYOffset) {
        this.widgetContainer.updateOptions({
            desktopBottomOffset: widgetPositionYOffset?.desktop ?? 0,
            mobileBottomOffset: widgetPositionYOffset?.mobile ?? 0,
            visible: !sdkState.hideWallet,
        });

        const updateVisibility = () => {
            const update = () => {
                this.widgetContainer.updateOptions({visible: !sdkState.hideWallet});
                this.updateScrim();
            };
            if (!this.isExpanded() || !sdkState.hideWallet) {
                update();
            } else {
                // TODO this dumb-shit should be event based
                setTimeout(update, DEFAULT_TRANSITION_DURATION_MS)
            }
            if (sdkState.hideWallet && !this.isPopupFlowActive() && this.isExpanded()) {
                this.changeRoute(WidgetRoutes.INDEX_COLLAPSED);
            }
        };
        userDataModule.authenticationChange.addListener(updateVisibility);
        sdkState.addChangeListener(updateVisibility);
        updateVisibility();
    }

    changeRoute(route) {
        if (this.isPopupFlowActive()) {
            this.pendingChangeRoutes.push(arguments);
            return;
        }
        this.widget.sendMessage(IFrameMessages.ROUTE_CHANGE, {route});
    }

    showModal(options) {
        if (this.panelDestroyFunction) {
            this.panelDestroyFunction();
        }
        if (this.isPopupFlowActive()) {
            const handler = this.activePanelHandler;
            setTimeout(() => handler.remove());
        } else {
            // Make sure the widget is collapsed because when the panel showed it might have been expanded.
            // And it is mandatory that the widget is collapsed when this gets destroyed even though it will be
            // expanded afterwards. Normally, the widget container gets updated from the iframe dispatch that
            // the route has changed, but in this case we need to move faster.
            this.changeRoute(WidgetRoutes.INDEX_COLLAPSED);
            this.widgetContainer.updateOptions({expanded: false});
        }
        const panel = new PanelElement(options, this.widget.node);
        const handler = {
            panel,
            remove: () => {
                // TODO @flow2 crap

                if (handler === this.activePanelHandler) {
                    panel.sendMessage(IFrameMessages.ROUTE_CHANGE, {url: PANEL_TYPE.empty});
                }
                this.panelDestroyFunction = () => {
                    if (handler === this.activePanelHandler) {
                        this.activePanelHandler = null;
                    }
                    panel.node = null;
                    panel.destroyNode();
                    this.widgetContainer.updateOptions({activePanel: this.isPopupFlowActive()});
                    for (const args of this.pendingChangeRoutes) {
                        this.changeRoute(...args);
                    }
                    this.pendingChangeRoutes = [];
                    this.updateScrim();

                    this.panelDestroyFunction = null;
                    clearTimeout(this.panelDestroyTimeout);
                    this.panelDestroyTimeout = null;
                };
                this.panelDestroyTimeout = setTimeout(this.panelDestroyFunction, DEFAULT_TRANSITION_DURATION_MS + 50);
                // This is for the logic regarding the transitioning. We destroy the panel after the given timeout.
            },
            updatePanel: panelOptions => {
                if (handler !== this.activePanelHandler) {
                    console.error("Panel has already been destroyed.");
                    return;
                }
                panel.updateOptions(panelOptions);
            }
        };

        this.activePanelHandler = handler;
        this.widgetContainer.updateOptions({activePanel: true});

        panel.onMount();
        // We need to have the panel params in the app before we change route so that the proper flow is perceived.
        panel.resendIFrameParams();
        panel.sendMessage(IFrameMessages.ROUTE_CHANGE);

        this.activePanelHandler = handler;
        this.updateScrim();

        panel.addSdkListener(IFrameMessages.DESTROY_PANEL, ({expandWallet}) => {
            handler.remove();
            if (expandWallet) {
                this.changeRoute(WidgetRoutes.INDEX_EXPANDED);
            }
        });

        return {panel, handler};
    }

    isExpanded() {
        return this.currentRoute !== WidgetRoutes.INDEX_COLLAPSED;
    }

    isPopupFlowActive() {
        return !!this.activePanelHandler;
    }

    updateScrim() {
        if (this.isPopupFlowActive() || (!sdkState.hideWallet && this.isExpanded())) {
            if (!this.scrim) {
                this.scrim = removeScrollOnScrim();
                this.widgetContainer.updateOptions({
                    extraRightOffset: this.scrim.scrollbarWidth,
                });
            }
        } else {
            if (this.scrim) {
                this.widgetContainer.updateOptions({
                    extraRightOffset: 0,
                });
                this.scrim.restore();
                this.scrim = null;
            }
        }
    }

    onRouteChange(event) {
        if (Object.values(WidgetRoutes).indexOf(event.route) !== -1 && event.appType === IFRAME_APP_TYPE.widget) {
            this.currentRoute = event.route;
        }

        if (!this.isPopupFlowActive()) {
            this.widget.updateOptions({route: this.currentRoute});
        }

        this.widgetContainer.updateOptions({expanded: this.isExpanded()});

        this.updateScrim();
        if (this.isExpanded()) {
            if (this.pulseAnimation) {
                this.pulseAnimation.remove();
                this.pulseAnimation = null;
            }
        }
    }
}

export const walletModule = new WalletModule();
