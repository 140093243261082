import {Condition} from "../engine/Condition";
import {loadUTMParams} from "../../../utils/Referrer";
import {UTM_PARAM_KEYS} from "../../../Constants";
import {toArray} from "../../../../stem-core/src/base/Utils.js";


/** @property {UJNodeDescription & {
 *      medium: string|string[]|undefined,
 *      campaign: string|string[]|undefined,
 *      source: string|string[]|undefined,
 *      term: string|string[]|undefined,
 *      content: string|string[]|undefined,
 *  }} options */
export class UTMCondition extends Condition {
    evaluate() {
        const utmParams = loadUTMParams();

        const matches = (paramKey) => {
            if (!this.options.hasOwnProperty(paramKey)) {
                return true; // no filter for this UTM parameter
            }
            return toArray(this.options[paramKey]).indexOf(utmParams[paramKey]) >= 0;
        };
        for (const key of UTM_PARAM_KEYS) {
            if (!matches(key)) {
                return false;
            }
        }
        return true;
    }
}
