import {IFrameElement} from "./IFrameElement";
import {IFrameMessages} from "../../messaging/IFrameMessages";
import {
    IS_PRODUCTION,
    TEST_MODE,
    GATE_TYPE, IFRAME_APP_TYPE, DEFAULT_TRANSITION_DURATION_MS, DEFAULT_TRANSITION, PANEL_TYPE,
} from "../../Constants";
import {delayThrottled} from "../../utils/Utils";
import {userDataModule} from "../../modules/UserDataModule";
import {Dispatcher} from "../../../stem-core/src/base/Dispatcher";
import {purchaseModule} from "../../modules/PurchaseModule";
import {isNumber, toArray} from "../../../stem-core/src/base/Utils.js";
import {evaluatePanelContent} from "../../PanelOptionsLogic.js";


// TODO This entire class seems in need of a rewrite
export class PanelElement extends IFrameElement {
    flowCheckpoint = new Dispatcher();
    flowFinish = new Dispatcher();
    flowSuccess = new Dispatcher();
    sizeChange = new Dispatcher();

    height = null;
    width = null;

    constructor(...args) {
        super(...args);
        if (!IS_PRODUCTION || TEST_MODE) {
            this.node.setAttribute("route", this.options.type);
            this.node.setAttribute("panel-type", this.options.type);
            this.node.setAttribute("gate-type", this.options.gateType);
            this.node.setAttribute("expanded", false);
        }
    }

    // TODO @cleanup remove the getters
    // TODO @cleanup what is this appType crap?
    get appType() {
        return IFRAME_APP_TYPE.panel;
    }

    getPaymentDetails() {
        return this.options.paymentDetails || purchaseModule.lastPaymentRequestData;
    }

    getPaymentRequestStatus() {
        const paymentDetails = this.getPaymentDetails();
        return purchaseModule.paymentRequests.get(paymentDetails?.offerId);
    }

    getIFrameParams() {
        return {
            ...super.getIFrameParams(),
            paymentDetails: this.getPaymentDetails(), // TODO @flow does this need to be passed like this?
        };
    }

    isFlowSuccess() {
        const flowSuccessType = toArray(this.options.flowSuccessType || this.options.type); // Default to the panel type
        const successLogic = {
            [PANEL_TYPE.subscribe]: () => userDataModule.isSubscriber() && !this.options.inlineFlow && !this.options.editRecurring, // TODO @flow2 this is a bit of hack for inline flows
            // [PANEL_TYPE.donation]: () => userDataModule.isDonor(), // TODO @flow2 this is wrong, it would be true if there were any donation made after
            [PANEL_TYPE.payment]: () => this.getPaymentRequestStatus()?.response != null,
            [PANEL_TYPE.auth]: () => userDataModule.isAuthenticated(),
        };

        for (const flowType of flowSuccessType) {
            const func = successLogic[flowType];
            if (func && func()) {
                return flowType;
            }
        }

        return null;
    }

    checkFlowSuccess() {
        const flowSuccessType = this.isFlowSuccess();
        if (flowSuccessType) {
            this.flowSuccess.dispatch(flowSuccessType);
        }
    }

    recalcPanelContent() {
        evaluatePanelContent(this.options);
        const {contentAST} = this.options;
        this.sendMessage(IFrameMessages.UPDATE_PANEL_CONTENT, {contentAST});
    }

    refreshStyle() {
        super.refreshStyle();
        this.setStyle({
            visibility: "visible",
        });
        // TODO @branch what if we only set only one of width or height
        if (this.height && this.width && this.options.gateType !== GATE_TYPE.popup) {
            this.setStyle({
                height: isNumber(this.height) ? this.height + "px" : this.height,
                width: isNumber(this.width) ? this.width + "px" : this.width,
                transition: DEFAULT_TRANSITION,
            });
        }
    }

    destroyNode() {
        this.flowFinish.dispatch();
        return super.destroyNode();
    }

    onMount() {
        super.onMount();

        this.addSdkListener(IFrameMessages.UPDATE_PANEL_SIZE, ({height, width}) => {
            if (height < 50) {
                // TODO @flow why the hell is 50 the magic number here?
                return;
            }
            this.height = height;
            this.width = width;
            this.refreshStyle();
            this.sizeChange.dispatch({height, width});
            if (!IS_PRODUCTION || TEST_MODE) {
                this.attachTimeout(() => this.node.setAttribute("expanded", "true"), DEFAULT_TRANSITION_DURATION_MS);
            }
        });

        this.addSdkListener(IFrameMessages.CHECK_FLOW_SUCCESS, () => {
            // TODO @flow2 also redraw here and maybe change the name of the function?
            this.checkFlowSuccess();
        });

        // There are cases when the panel size is not calculated correctly while the user is scrolling
        this.attachEventListener(window, "resize", delayThrottled(() => this.sendMessage(IFrameMessages.REQUEST_UPDATE_PANEL_SIZE)));

        // In some browsers, we need to recalculate the panel size when the current tab becomes active
        // Otherwise, there is a risk for the panel element to be invisible or to have an odd height/width
        this.attachEventListener(document, "visibilitychange", () => {
            if (document.visibilityState === "visible") {
                this.sendMessage(IFrameMessages.REQUEST_UPDATE_PANEL_SIZE);
            }
        });

        this.addSdkListener(IFrameMessages.SCROLL_IFRAME_TOP, ({windowScroll}) => {
            this.node?.scrollIntoView({behavior: "smooth"});
            if (windowScroll) {
                window.scrollTo(windowScroll);
            }
        });
        this.addSdkListener(IFrameMessages.FLOW_CHECKPOINT, ({event}) => this.flowCheckpoint.dispatch(event));

        this.attachListener(purchaseModule.paymentRequestReceived, () => this.resendIFrameParams());
        this.attachListener(purchaseModule.paymentRequestCompleted, () => this.resendIFrameParams());

        if (!IS_PRODUCTION || TEST_MODE) {
            this.addSdkListener(IFrameMessages.ROUTE_CHANGE, ({route}) => {
                this.node.setAttribute("route", route);
            });
        }

        this.attachListener(userDataModule.authenticationChange, () => {
            // It might have changed.
            // TODO @flow2 this would need to be handled generically
            this.recalcPanelContent();
        })

        // TODO @flow2 fuck you bloody bastards, zero control or visibility here
        // TODO @flow2 It looks like we don't check the flow for completion when a subscription is made since we want to preserve the confirmation screen.
        //  Just make that a part of the flow and be done with it.
        this.checkFlowSuccess();
        this.attachListener(userDataModule.userPreferencesChange, () => this.checkFlowSuccess());
        this.attachListener(purchaseModule.paymentRequestCompleted, () => this.checkFlowSuccess());
    }
}
