import {GATE_TYPE, PANEL_TYPE, USER_JOURNEY_ACTION_TYPE} from "../../../Constants";
import {panelModule} from "../../PanelModule";
import {SessionActionThrottler} from "../../SessionActionThrottler";
import {walletModule} from "../../WalletModule";
import {merchantFunctionModule} from "../../merchant-state/MerchantFunctionModule";
import {evaluateTemplate} from "../../TemplateEvaluation";
import {userDataModule} from "../../UserDataModule";
import {uxFlowModule} from "../../UXFlowModule";
import {getMerchantUserJourney} from "../../PublicData";
import {merchantVariableModule} from "../../merchant-state/MerchantVariableModule";
import {markURLVisited} from "../../../utils/PageAccessHelpers.js";
import {base64Decode, toArray} from "../../../../stem-core/src/base/Utils.js";
import {editPanel} from "../../../PanelOptionsLogic";
import {isString} from "../../../../stem-core/src/base/Utils.js";

/** @typedef {{
 *     type: string,
 *     callback: string|undefined,
 *     blinkAction: string|undefined,
 *     loop: boolean|undefined,
 *     selector: string|undefined,
 *     panel: number|string|undefined,
 *     panelType: string|undefined,
 *     gateType: string|undefined,
 *     context: Array|Object|undefined,
 * }} UJActionDescription
 * @typedef {UJActionDescription | UJActionDescription[]} UserJourneyAction */

/** @typedef {ThrottleRule|ThrottleRule[]} UserJourneyThrottle */


export class JourneyActionRunner {
    constructor(journey, enableThrottle=true) {
        const {action, throttle} = journey.options;

        this.journey = journey;
        this.actions = toArray(action);

        if (enableThrottle && throttle) {
            let throttleOptions = evaluateTemplate(toArray(throttle));
            this.throttler = new SessionActionThrottler(journey.alias + "-throttle", throttleOptions);
        } else {
            this.throttler = null;
        }
    }

    runAction() {
        for (const action of this.actions) {
            if (action.type === USER_JOURNEY_ACTION_TYPE.createPanel && action.gateType === GATE_TYPE.popup) {
                // TODO: Re-visit these quirks of the journey popups. This is
                //  for compatibility with the old DonationsModule implementation.
                //  Maybe move this to PanelModule, or have explicit priority
                //  per-flow, etc. Or maybe just add these as conditions explicitly
                //  in the associated journeys.

                // The journey popups have the smallest priority, so if widget is
                // expanded / popup is displayed, we don't show it.
                if (walletModule.isExpanded() || walletModule.isPopupFlowActive()) {
                    return;
                }
            }
        }

        if (this.throttler != null && !this.throttler.execute()) {
            return;
        }

        for (const action of this.actions) {
            runJourneyAction(this.journey, action);
        }
    }
}

function runJourneyAction(journey, action) {
    action = evaluateTemplate(action);

    const type = action.type || USER_JOURNEY_ACTION_TYPE.callback;
    switch (type) {
        case USER_JOURNEY_ACTION_TYPE.callback: {
            for (const key of Object.keys(action)) {
                if (key === "type") {
                    continue;
                }
                merchantFunctionModule.call([key, ...toArray(action[key]), journey]);
            }
            break;
        }
        // TODO: @Darius Deprecate everything below. Deprecate action type,
        //  everything can be done through built-in functions now, and the journey
        //  syntax for it is much nicer.
        case USER_JOURNEY_ACTION_TYPE.blinkAction: {
            runBlinkAction(action.blinkAction);
            break;
        }
        case USER_JOURNEY_ACTION_TYPE.setVariable: {
            for (const key of Object.keys(action)) {
                if (key !== "type") {
                    merchantVariableModule.set(key, action[key]);
                }
            }
            break;
        }
        case USER_JOURNEY_ACTION_TYPE.editPanel: {
            const options = {
                ...action,
            };
            delete options.type;
            delete options.panel;
            editPanel(action.panel, options);
            break;
        }
        case USER_JOURNEY_ACTION_TYPE.createPanel: {
            const {loop, selector} = action;
            const options = {
                ...action,
                journeyId: journey.id,
                journeyAlias: journey.alias,
            };
            delete options.loop;
            delete options.type;
            if (loop) {
                panelModule.addRule(options);
            } else {
                delete options.selector;
                panelModule.createPanel(selector, options);
            }
            break;
        }
        default: break;
    }
}

// TODO @cleanup all of these should probably by a call to a SDK method
const blinkActionMap = {
    login: (panel) => uxFlowModule.enterFlow(PANEL_TYPE.auth, {panel}), // TODO @cleanup blinkSDK.promptAuth(...args)
    donate: (panel) => uxFlowModule.enterFlow(PANEL_TYPE.donation, {
        panel,
        skipCTA: userDataModule.isAuthenticated(),
    }),
    giftSubscription: (panel) => uxFlowModule.enterFlow(PANEL_TYPE.giftSubscription, {panel}),
    subscribe: (panel) => uxFlowModule.enterFlow(PANEL_TYPE.subscribe, {panel}),
    newsletter: (panel) => panelModule.createPopup({
        panel,
        type: PANEL_TYPE.newsletter,
        skipCTA: true,
    }),
    setToken: (token) => {
        try {
            token = base64Decode(token);
            // TODO @Mihai standardize how our tokens are formatted
            // TODO @Mihai this should be a one-time token, to not actually give away the session
            userDataModule.setMerchantToken({
                key: token.t,
                expiresAt: token.e,
            });
        } catch (e) {
            console.error("Failed to set the token", e);
        }

    },
    markVisit: (href) => markURLVisited(href),
    dashboard: (panel) => panelModule.createPopup({panel, type: PANEL_TYPE.dashboard}),
};

// An example blink action string is "socialAuth!facebook-subscribe!yearly!FB10",
// which should be translated into the following sequence of actions:
//  socialAuth("facebook");
//  subscribe("yearly", "FB10");
export function runBlinkAction(actions) {
    if (isString(actions)) {
        actions = actions.split(",");
    }
    for (const action of toArray(actions)) {
        if (!isString(action)) {
            const runner = new JourneyActionRunner({options: {action}, alias: "inline_journey"}, false);
            runner.runAction();
            continue;
        }

        // For each individual action, split by "!" to separate the action and arguments.
        // The action will be the first string after splitting.
        const blinkActionArgs = action.split("!");
        if (blinkActionArgs.length === 0) {
            continue;
        }
        const journey = getMerchantUserJourney(blinkActionArgs[0]);
        if (journey) {
            (new JourneyActionRunner(journey, false)).runAction();
        } else if (blinkActionMap[blinkActionArgs[0]]) {
            blinkActionMap[blinkActionArgs[0]](...blinkActionArgs.slice(1));
        }
    }
}
