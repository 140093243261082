import {AndCondition, OrCondition, NotCondition, TrueCondition} from "../conditions/Operators";
import {NthPageVisitCondition} from "../conditions/NthPageVisitCondition";
import {InactiveCondition} from "../conditions/InactiveCondition";
import {StayOnPageCondition} from "../conditions/StayOnPageCondition";
import {UserAgentCondition} from "../conditions/UserAgentCondition";
import {PageScrollCondition} from "../conditions/PageScrollCondition";
import {FunctionCondition} from "../conditions/FunctionCondition";
import {VariableCondition} from "../conditions/VariableCondition";
import {UTMCondition} from "../conditions/UTMCondition";
import {PathCondition, ReferrerCondition} from "../conditions/PathCondition";
import {CookieCondition} from "../conditions/CookieCondition";
import {ExprCondition} from "../conditions/ExprCondition.js";
import {userDataModule} from "../../UserDataModule.js";
import {MakeSimpleCondition} from "./Condition.js";
import {adBlockDetectionDispatcher, isAdBlockEnabled} from "../../../utils/AdBlockDetection.js";
import {DeviceCondition} from "../conditions/DeviceCondition.js";

export const OperatorType = {
    AND: "and",
    OR: "or",
    NOT: "not",
};

function getUserDataConditions() {
    const methods = userDataModule.exposeMerchantFunctions();
    for (const [key, value] of Object.entries(methods)) {
        methods[key] = MakeSimpleCondition(value, userDataModule.userPreferencesChange);
    }
    return methods;
}

/** @type {Object<string, typeof Condition>} */
export const ConditionClassMap = {
    [OperatorType.AND]: AndCondition,
    [OperatorType.OR]: OrCondition,
    [OperatorType.NOT]: NotCondition,
    "true": TrueCondition,
    "expr": ExprCondition,    
    "function": FunctionCondition,
    "variable": VariableCondition,
    "device": DeviceCondition,
    "inactive": InactiveCondition,
    "stayOnPage": StayOnPageCondition,
    "pageScroll": PageScrollCondition,
    "nthPage": NthPageVisitCondition,
    "adBlockEnabled": MakeSimpleCondition(isAdBlockEnabled, adBlockDetectionDispatcher),
    "userAgent": UserAgentCondition,
    "utm": UTMCondition,
    "path": PathCondition,
    "referrer": ReferrerCondition,
    "cookie": CookieCondition,
    ...getUserDataConditions(),
};
