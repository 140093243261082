export function makeUniqueSelector(node) {
    if (!node) {
        return undefined;
    }
    let selector = "";
    let childSelector = "";
    for (;node && node !== document.body; node = node.parentNode) {
        selector = node.className.split(" ").map(cls => cls ? "." + CSS.escape(cls) : "").join("")
            + (node.id ? "#" + CSS.escape(node.id) : "") + childSelector + selector;
        let tagName = node.tagName.toLowerCase();
        if (document.querySelectorAll(tagName + selector).length === 1) {
            return tagName + selector;
        }
        if (node.parentNode) {
            childSelector = `>:nth-child(${[...node.parentNode.children].indexOf(node) + 1})`
        } else {
            childSelector = "";
        }
    }
    return node ? node.tagName.toLowerCase() + selector : selector;
}
