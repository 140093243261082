import {getWindowWidth, isSmallScreen} from "../../blinkpay/UtilsLib";
import {MIN_SUPPORTED_WINDOW_WIDTH} from "../Constants";

export function getSandboxProperties() {
    return (
        "allow-popups allow-scripts allow-same-origin allow-popups-to-escape-sandbox " +
        " allow-top-navigation-by-user-activation allow-forms"
    );
}

export function getClientIdFromCurrentScriptUrl() {
    try {
        return (new URL(document.currentScript.src)).searchParams.get("clientId");
    } catch {
        return null;
    }
}

export function formatAgoMessage(timestamp) {
    const duration = Date.now() - timestamp;
    const msToMinute = 60 * 1000;
    const msToHour = msToMinute * 60;
    const msToDay = msToHour * 24;
    const days = parseInt(duration / msToDay, 10);
    const hours = parseInt((duration % msToDay) / msToHour, 10);
    const minutes = parseInt((duration % msToHour) / msToMinute, 10);
    let result = `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    if (days || hours) {
        result = `${hours} hour${hours === 1 ? "" : "s"}, ` + result;
        if (days) {
            result = `${days} day${days === 1 ? "" : "s"}, ` + result;
        }
    }
    return result;
}

export function delayThrottled(func, ms) {
    let timeoutId = null;
    return () => {
        if (timeoutId == null) {
            timeoutId = setTimeout(() => {
                func();
                timeoutId = null;
            }, ms);
        }
    };
}

export function delayDebounced(func, ms) {
    let timeoutId = null;
    return () => {
        if (timeoutId != null) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func();
            timeoutId = null;
        }, ms);
    };
}

// Checks if the browser has support for passive event listeners.
// When an event listener is set as passive, it indicates that the callback will not cancel
// the event by invoking preventDefault().
let supportsPassiveEventsCached = null;
export function supportsPassiveEvents() {
    // Check if the variable has been previously initialized
    if (supportsPassiveEventsCached == null) {
        try {
            window.addEventListener("test", null, Object.defineProperty({}, "passive", {
                get: () => {
                    supportsPassiveEventsCached = true;
                    return null;
                }})
            );
            // eslint-disable-next-line no-empty
        } catch (e) {
        }
    }

    return supportsPassiveEventsCached;
}

export function getWidgetRightPosition() {
    return isSmallScreen() ? 10 : 24;
}

export function filterObjectKeys(obj, keys) {
    const newObj = {};
    for (const key of Object.keys(obj)) {
        if (keys.indexOf(key) >= 0) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}


export function computeScreenScaleFactor() {
    if (getWindowWidth() < MIN_SUPPORTED_WINDOW_WIDTH) {
        return (MIN_SUPPORTED_WINDOW_WIDTH / getWindowWidth());
    }
    return 1;
}
