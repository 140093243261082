// These are done here to avoid circular dependencies. This is called at the
// very top of the BlinkSDK constructor, so it's still safe to assume they
// are available at all points throughout the code.

import {merchantBuiltinFunctions} from "./Builtins.js";
import {isURLViewed} from "../../utils/PageAccessHelpers.js";
import {getQueryParam, getWindowWidth, isSmallScreen} from "../../../blinkpay/UtilsLib.js";
import {merchantVariableModule} from "./MerchantVariableModule.js";
import {getCookie, setCookie} from "../../utils/CookieHelpers.js";
import {panelModule} from "../PanelModule.js";
import {runBlinkAction} from "../user-journey/engine/JourneyActionRunner.js";
import {editPanel} from "../../PanelOptionsLogic";
import {userDataModule} from "../UserDataModule.js";

// TODO @cleanup Fuck, why was this here, hidden from the panel editor.
export function initMerchantBuiltinFunctions() {
    Object.assign(merchantBuiltinFunctions, {
        ...userDataModule.exposeMerchantFunctions(),
        chooseRandom: (values) => {
            return values && values[Math.floor(values.length * Math.random())];
        },
        isURLViewed,
        screenWidth: getWindowWidth,
        isSmallScreen,
        getQueryParam,
        setCookie,
        getCookie,
        getVariable: (key) => merchantVariableModule.get(key),
        hasVariable: (key) => merchantVariableModule.has(key),
        setVariable: (key, value, scope) => merchantVariableModule.set(key, value, scope),
        deleteVariable: (key) => merchantVariableModule.delete(key),
        addVariables: (variables) => merchantVariableModule.add(variables),
        createPanel: (options, journey) => {
            // Loop means that we're adding this panel rule to be run in the background
            const {loop, selector} = options;
            options = {
                ...options,
            };
            if (journey) {
                options.journeyId = journey.id;
                options.journeyAlias = journey.alias;
            }
            delete options.loop;
            if (loop) {
                panelModule.addRule(options);
            } else {
                delete options.selector;
                panelModule.createPanel(selector, options);
            }
        },
        editPanel: (options) => editPanel(options.panel, {
            ...options,
            panel: undefined,
        }),
        runBlinkAction,
    });
}
