import {StorageScope, getStorage} from "./merchant-state/MerchantStorage";

/** @typedef {{count: number, duration: number|undefined, scope: string|undefined}} ThrottleRule */

// Class that keeps in a storage key an array of timestamps of when something was executed
// And makes sure to not break any rule of doing something too frequently
class ScopedActionThrottler {
    /** @type {number[]} */
    cachedTimestamps = []; // keep track of local

    /** @type {ThrottleRule[]} */
    throttleRules = [];

    /** @type {number} */
    maxCount = 0;

    /** @param {string} name
     * @param {MerchantStorage} storage
     * @param {ThrottleRule[]} throttleRules */
    constructor(name, storage, throttleRules) {
        this.name = name;
        this.storage = storage;
        for (const throttleRule of throttleRules) {
            this.throttleRules.push({
                duration: (throttleRule.duration || Infinity) * 1000,
                count: throttleRule.count,
            });
            if (throttleRule.count > this.maxCount) {
                this.maxCount = throttleRule.count;
            }
        }
        // TODO: might want to sanity check maxCount is below a certain value (prob max 256)
    }

    // Return true or false if the action can be executed (true means not throttled)
    // If not throttled, also updated the array of timestamps in storage.
    execute(dryRun) {
        const currentTime = Date.now();
        // Deep copy the cached timestamps (to not modify them by mistake if we're doing a dry run).
        let lastRuns = this.storage.getItem(this.name) || [...this.cachedTimestamps];
        lastRuns.sort(); // Just to be sure

        for (let throttleRule of this.throttleRules) {
            const index = lastRuns.length - throttleRule.count;
            if (index >= 0 && currentTime - lastRuns[index] < throttleRule.duration) {
                return false;
            }
        }

        // We're not breaking any throttle rules if we got here
        lastRuns.push(currentTime);
        if (lastRuns.length > this.maxCount) {
            lastRuns = lastRuns.slice(lastRuns.length - this.maxCount);
        }
        // Update the throttles times in the session storage
        if (!dryRun) {
            this.cachedTimestamps = lastRuns;
            this.storage.setItem(this.name, lastRuns);
        }
        return true;
    }
}

export class SessionActionThrottler {
    /** @type{ScopedActionThrottler[]} */
    throttlers = [];

    /** @param {string} name
     * @param {ThrottleRule[]} throttleRules */
    constructor(name, throttleRules) {
        for (const scope of Object.values(StorageScope)) {
            const scopedRules = throttleRules.filter(rule => rule.scope === scope || (scope === StorageScope.PERMANENT && !rule.scope));
            if (scopedRules.length) {
                this.throttlers.push(new ScopedActionThrottler(name, getStorage(scope), scopedRules));
            }
        }
    }

    // Return true or false if the action can be executed (true means not throttled)
    // If not throttled, also updated the session storage array of timestamps.
    execute(dryRun) {
        if (this.throttlers.filter(throttler => !throttler.execute(true)).length) {
            return false;
        }
        if (!dryRun) {
            this.throttlers.forEach(throttler => throttler.execute());
        }
        return true;
    }
}
