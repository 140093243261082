import {Condition} from "../engine/Condition.js";

/** @property {{seconds: number}} options */
export class InactiveCondition extends Condition {
    constructor(...args) {
        super(...args);

        let inactiveTimeout = null;
        const resetInactiveStatus = () => {
            this.isInactive = false;
            clearTimeout(inactiveTimeout);
            inactiveTimeout = this.attachTimeout(() => {
                this.isInactive = true;
                this.requestReEval();
            }, this.options.seconds * 1000);
        }

        resetInactiveStatus();
        this.attachEventListener(window, "mousemove", resetInactiveStatus);
        this.attachEventListener(window, "scroll", resetInactiveStatus);
        this.attachEventListener(window, "click", resetInactiveStatus);
    }

    evaluate() {
        return this.isInactive;
    }
}
