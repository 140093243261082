// TODO @cleanup this file needs a new pass through it
import {DEFAULT_PANEL_CONTAINERS, GATE_TYPE, PANEL_TYPE, PANEL_INSERTED_ATTR, FLOW_TYPE} from "../Constants";
import {CoverGate} from "../ui/gates/CoverGate";
import {PreviewGate} from "../ui/gates/PreviewGate";
import {evaluatePanelOptions} from "../PanelOptionsLogic";
import {walletModule} from "./WalletModule";
import {BannerGate} from "../ui/gates/BannerGate";
import {isString} from "../../stem-core/src/base/Utils";
import {PanelElement} from "../ui/elements/PanelElement";
import {merchantFunctionModule} from "./merchant-state/MerchantFunctionModule";
import {CleanupJobs} from "../../stem-core/src/base/Dispatcher";
import {isMerchantActive} from "./PublicData";
import {GateElement} from "../ui/elements/GateElement.js";
import {PopupGate} from "../ui/gates/PopupGate.js";


// TODO @Mihai merge these, just pass in some options
const gateMap = {
    [GATE_TYPE.cover]: CoverGate,
    [GATE_TYPE.preview]: PreviewGate,
    [GATE_TYPE.banner]: BannerGate,
    [GATE_TYPE.popupGate]: PopupGate
};

class PanelModule {
    pendingRequests = [];
    rules = DEFAULT_PANEL_CONTAINERS;
    cachedBannerGates = new Map(); // TODO @flow this is a temp hack for banners, so that only a single banner of a specific type is displayed. Merge with popup dedup logic

    init() {
        // Pre-create the modal panel.
        const panel = new PanelElement({type: PANEL_TYPE.empty, gateType: GATE_TYPE.popup}, walletModule.widget.node);
        panel.initialize();

        this.scanForPanels();
        // TODO(@sdk-deprecated): We shouldn't automatically add an interval anymore.
        setInterval(() => this.scanForPanels(), 200);
    }

    addRule(rule) {
        this.rules.push(rule);
        // After adding a new rule, run a scan to immediately apply it.
        this.scanForPanels();
    }

    scanForPanels(rootNode = document) {
        for (const rule of this.rules) {
            for (const container of rootNode.querySelectorAll(rule.selector)) {
                if (container.getAttribute(PANEL_INSERTED_ATTR) === "true") {
                    return;
                }
                container.setAttribute(PANEL_INSERTED_ATTR, "true");
                const options = {...rule};
                delete options.selector;
                this.createPanel(container, options);
            }
        }
    }

    resolvePendingRequests() {
        const pendingRequests = this.pendingRequests;
        this.pendingRequests = null;
        for (const args of pendingRequests) {
            this.createPanel(...args);
        }
    }

    createPanel(nodesOrSelector, options) {
        nodesOrSelector = nodesOrSelector || document.body;

        // If the above method hasn't been called, this class can't create panels, so it has to queue them.
        // The place where the previous method gets called is relevant.
        if (this.pendingRequests != null) {
            this.pendingRequests.push(arguments);
            return;
        }

        // Transition from multiple possible nodes to a single one per createPanel call.
        if (isString(nodesOrSelector)) {
            nodesOrSelector = [...document.querySelectorAll(nodesOrSelector)];
        }

        // TODO @cleanup, just disable this functionality of accepting an array?
        if (Array.isArray(nodesOrSelector)) {
            // TODO @branch we're opening ourselves to a ddos here, cap the array length
            nodesOrSelector.length = Math.min(4, nodesOrSelector.length);
            for (const node of nodesOrSelector) {
                this.createPanel(node, options);
            }
            return;
        }

        // STEP 1: Build the final options and prepare to instantiate the panel.

        options = evaluatePanelOptions(options);
        const removeAnonymousFunctionJobs = new CleanupJobs();
        // All the functions added for the panel are passed through the merchant function module. We need it so that the
        // errors don't get thrown and our code doesn't break.
        for (const maybeFunctionOption of ["onFlowCheckpoint", "onFlowFinish", "onFlowSuccess", "onMount"]) {
            if (typeof options[maybeFunctionOption] === "function") {
                const functionKey = merchantFunctionModule.addAnonymous(options[maybeFunctionOption]);
                options[maybeFunctionOption] = functionKey;
                removeAnonymousFunctionJobs.add(() => merchantFunctionModule.delete(functionKey));
            }
        }

        const gateType = options.gateType;

        if (!isMerchantActive()) {
            console.error("[BlinkSDK] Blink SDK is inactive!");
            return;
        }

        // STEP 2: Instantiate and mount the panel and the gate.
        const domNode = nodesOrSelector;
        let panel, handler;

        if (gateType === GATE_TYPE.popup) {
            // Only one popup may be active at a time, so a global panel is used
            const modalObj = walletModule.showModal(options); // TODO this is called from walletModule since they share an iframe
            panel = modalObj.panel;
            handler = modalObj.handler;
        } else {
            // Create a panel, create a gate that contains that panel, mount the gate in the DOM and build the handler.
            const GateClass = gateType ? gateMap[gateType] : GateElement;

            if (GateClass == null) {
                console.error("[BlinkSDK] Unknown gate type", gateType);
                return;
            }

            const panelAlias = options.panel;

            // No panelAlias means we're in preview. Make explicit. TODO @flow2 fix caching even if the node gets destroyed in all cases
            if (gateType === GATE_TYPE.banner && panelAlias && this.cachedBannerGates.has(panelAlias)) {
                const gate = this.cachedBannerGates.get(panelAlias);
                handler = gate.getInteractiveHandlers();
                panel = gate.panel;
                panel.updateOptions(options);
            } else {
                panel = new PanelElement(options);
                const gate = new GateClass(options, panel);
                gate.mount(domNode);
                handler = gate.getInteractiveHandlers();

                if (gateType === GATE_TYPE.banner) {
                    this.cachedBannerGates.set(panelAlias, gate);
                }
            }
        }

        // STEP 3: Handle all the listeners and the post mount logic of the panel.

        // Remove all of the panel's functions lying in the merchant function module once the panel is destroyed.
        panel.addCleanupJob(removeAnonymousFunctionJobs);

        // Map the flow functions to the corresponding listeners.
        if (options.onFlowFinish) {
            panel.flowFinish.addListener((...args) => merchantFunctionModule.call(options.onFlowFinish, ...args));
        }

        if (options.onFlowCheckpoint) {
            panel.flowCheckpoint.addListener((event) => merchantFunctionModule.call(options.onFlowCheckpoint, event));
        }

        const onFlowSuccess = (flowType) => {
            // TODO @flow2 this is a hack to keep a thank-you screen open after the flow succeeded
            if (gateType !== GATE_TYPE.popup || (flowType !== FLOW_TYPE.donation && flowType !== FLOW_TYPE.subscription)) {
                handler.remove();
            }

            if (options.onFlowSuccess) {
                merchantFunctionModule.call(options.onFlowSuccess);
            }
        };

        if (panel.isFlowSuccess()) {
            onFlowSuccess();
        } else {
            panel.flowSuccess.addListener(onFlowSuccess);
        }

        if (options.onMount) {
            merchantFunctionModule.call(options.onMount, handler);
        }
    }

    createPopup(options) {
        this.createPanel(document.body, {
            ...options,
            gateType: GATE_TYPE.popup,
        });
    }
}

export const panelModule = new PanelModule();
