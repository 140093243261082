import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {BLINK_MESSAGE_IDENTIFIER, formatType} from "../../blink-sdk/messaging/IFrameMessages";

function dispatchWindowMessage(windowElement, type, data, appType) {
    const event = {
        ...data,
        appType,
        type: formatType(type),
    };
    if (windowElement === window && window.postBlinkMessageSync) {
        window.postBlinkMessageSync(event);
    } else {
        windowElement.postMessage(event, "*");
    }
}

class IFrameConnection extends Dispatchable {
    appType = null;
    allIframesDispatch = new Dispatchable();

    constructor() {
        super();

        // TODO @Mihai: Maybe reconsider
        if (!window.blinkIframeConnections) {
            window.blinkIframeConnections = [this];
        } else {
            window.blinkIframeConnections.push(this);
        }

        if (!window.postBlinkMessageSync) {
            window.postBlinkMessageSync = function(event) {
                for (const connection of window.blinkIframeConnections) {
                    connection.processWindowEventData(event);
                }
            }
        }

        this.attachEventListener(window, "message", event => {
            let {data} = event;

            if (typeof data !== "object") {
                return;
            }

            if (event.source === window && this.appType === data.appType) {
                return;
            }

            data.eventOrigin = event.origin;

            this.processWindowEventData(data);
        });
    }

    processWindowEventData(data) {
        if (!data.eventOrigin) {
            data.eventOrigin = window.location.origin;
        }

        const {type} = data;

        if (!(typeof type === "string" || type instanceof String) || !type.startsWith(BLINK_MESSAGE_IDENTIFIER)) {
            return;
        }

        this.allIframesDispatch.dispatch(type.substring(BLINK_MESSAGE_IDENTIFIER.length), data);

        if (data.appType && this.appType && this.appType !== data.appType) {
            return;
        }

        this.dispatch(type.substring(BLINK_MESSAGE_IDENTIFIER.length), data);
    }

    setAppType(appType) {
        this.appType = appType;
    }

    sendToSDK(type, data = {}) {
        dispatchWindowMessage(window.parent, type, data, this.appType);
    }

    sendToOpener(type, data = {}) {
        dispatchWindowMessage(window.opener, type, data, this.appType);
    }

    // This method is used for sending messages from an iframe to the others, this is useful for manual confirmed
    // payments and token sharing between iframes when the user has third party storage disabled.
    sendToIFrames(type, data={}) {
        const {frames} = window.parent;
        for (let index = 0; index < frames.length; index += 1) {
            try {
                const frame = frames[index];
                if (frame.origin === window.origin) {
                    dispatchWindowMessage(frame, type, data, this.appType);
                }
            } catch (e) {
                // TODO @cleanup maybe this iframe should be marked as dead?
            }
        }
    }

    addIframesListener(type, callback) {
        return this.allIframesDispatch.addListener(type, (event, ...args) => {
            // This callback is intended to be called only if the message dispatched comes from another of our iframes.
            if (event.eventOrigin !== window.origin) {
                return;
            }
            callback(event, ...args);
        });
    }
}

export const iFrameConnection = new IFrameConnection();
