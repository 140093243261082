import {Dispatcher} from "../../stem-core/src/base/Dispatcher.js";

// For good integration between CookieCondition and setCookie builtin function.
export const setCookieDispatcher = new Dispatcher();


export function setCookie(key, value, age) {
    document.cookie = `${key}=${value}; path=/; max-age=${age};`;
    setCookieDispatcher.dispatch();
}

export function getCookie(key) {
    let cookies = (document.cookie || "").split(";");
    for (let cookie of cookies) {
        cookie = cookie.trim();
        if (cookie.startsWith(key + "=")) {
            return cookie.substring(key.length + 1);
        }
    }
    return "";
}
