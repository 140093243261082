import {GateElement, GateStyle} from "../elements/GateElement";

const BannerGateStyle = {
    gate: {
        ...GateStyle.gate,
        background: "#FFF",
        boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        boxSizing: "border-box",
        left: 0,
        bottom: 0,
        width: "100%",
        position: "fixed",
        zIndex: 9999997, // It has to be under the scrim and widget.
        height: null,
    }
};

export class BannerGate extends GateElement {
    styleSheet = BannerGateStyle;

    refreshStyle() {
        super.refreshStyle();
        this.setStyle(this.options.customStyle ?? {});
    }
}
