import {Dispatcher} from "../../stem-core/src/base/Dispatcher.js";

const INITIAL_DELAY = 400;
const NUM_BAIT_ATTEMPTS = 5;
const BAIT_ATTEMPT_INTERVAL = 200;
const BAIT_ATTEMPT_NUM_CHECKS = 5;
const BAIT_ATTEMPT_CHECK_INTERVAL = 200;

let initializedAdBlockDetection = false;
let cachedResponse = null;
export let adBlockDetectionDispatcher = new Dispatcher(); // Subscribe to this to find out when something changes

const baitClasses = [
    "pub_300x250",
    "pub_300x250m",
    "pub_728x90",
    "text-ad",
    "textAd",
    "text_ad",
    "text_ads",
    "text-ads",
    "text-ad-links",
    "banner-ad-box",
    "mod_ad_square",
    "mod_ad_side",
    "mod_ad_main",
    "ad_title",
    "ad_pic",
    "banner-ad-box",
    "ad-banner",
    "ad960",
    "index_ad_column2",
    "index_ad_a6",
    "index_ad_a5",
    "index_ad_a4",
    "mod_r_ad1",
    "mod_r_ad",
    "mod_index_ad",
    "mod_ad_text",
    "mod_ad_t25",
    "index_ad_a2",
    "hp_textlink_ad",
    "head_ad",
    "channel_ad_2016",
    "ad_hover_href",
    "adClose",
    "top-ads",
    "ad-bottom",
    "ad-region",
    "ad728x90",
    "adCell",
    "adVertical",
    "adWrapper",
    "ad_click",
    "ad_content",
    "ad_image",
    "adpic",
    "ads-image",
    "ads_area",
    "ads_title",
    "adsense-right",
    "advert-wide",
    "advertiseText",
    "b_adLastChild",
    "banner-300x250",
    "banner-728",
    "block-ads",
    "centered-ad",
    "content_ad",
    "contentad",
    "detail-ads",
    "google_adsense",
    "gujAd",
    "house-ad",
    "internal-ad",
    "is-sponsored",
    "item-ad",
    "jquery-script-ads",
    "ligatus",
    "middlead",
    "native-ad",
    "overlay-ad",
    "related-ads",
    "side-ads",
    "sidead",
    "sponsor-link",
    "sticky-ad-wrapper",
    "top-ads",
    "vce-ad-below-header",
    "vce-ad-container",
    "video-ads",
    "wide-ad",
    "widget-ads"
];
const baitIDs = [
    "ad-left",
    "adBannerWrap",
    "ad-frame",
    "ad-header",
    "ad-img",
    "ad-inner",
    "ad-label",
    "ad-lb",
    "ad-footer",
    "ad-container",
    "ad-container-1",
    "ad-container-2",
    "Ad300x145",
    "Ad300x250",
    "Ad728x90",
    "AdArea",
    "AdFrame1",
    "AdFrame2",
    "AdFrame3",
    "AdFrame4",
    "AdLayer1",
    "AdLayer2",
    "Ads_google_01",
    "Ads_google_02",
    "Ads_google_03",
    "Ads_google_04",
    "DivAd",
    "DivAd1",
    "DivAd2",
    "DivAd3",
    "DivAdA",
    "DivAdB",
    "DivAdC",
    "AdImage",
    "AdDiv",
    "AdBox160",
    "AdContainer",
    "glinkswrapper",
    "adTeaser",
    "banner_ad",
    "adBanner",
    "adAd",
    "bannerad",
    "ad_box",
    "ad_channel",
    "adserver",
    "bannerid",
    "adslot",
    "popupad",
    "adsense",
    "google_ad",
    "outbrain-paid",
    "sponsored_link",
    "adframe_wrap_ad",
    "bunyad_ads_",
    "l_qq_com",
    "adzone_",
    "sponsorText",
    "dfp-tlb",
];
const baitStyle = {
    height: "1px",
    width: "1px",
    opacity: "1",
    position: "absolute",
    left: "-999px",
    top: "-999px",
};

function insertBaitNode() {
    const baitNode = document.createElement("div")
    baitNode.id = baitIDs[Math.floor(Math.random() * baitIDs.length)]
    baitNode.setAttribute("class", baitClasses.join(" "))
    baitNode.setAttribute("ad-id", "googlead_");
    Object.assign(baitNode.style, baitStyle);
    document.body.appendChild(baitNode);
    return baitNode;
}

function isNodeBlocked(node) {
    return !document.body.contains(node) ||
        window.document.body.getAttribute("abp") !== null ||
        node.clientHeight === 0 ||
        node.clientWidth === 0 ||
        node.offsetParent === 0 ||
        node.offsetHeight === 0 ||
        node.offsetLeft === 0 ||
        node.offsetTop === 0 ||
        node.offsetWidth === 0 ||
        node.style.opacity === 0 ||
        node.style.display === "hidden" ||
        node.style.visibility === "none";
}

function setCachedResponse(value) {
    cachedResponse = value;
    adBlockDetectionDispatcher.dispatch(value);
}

function checkNodeBlocked(baitAttemptCheckIndex, node) {
    if (isNodeBlocked(node)) {
        setCachedResponse(true);
    } else {
        if (baitAttemptCheckIndex === BAIT_ATTEMPT_NUM_CHECKS) {
            setCachedResponse(false);
        } else {
            setTimeout(() => {
                checkNodeBlocked(baitAttemptCheckIndex + 1, node);
            }, BAIT_ATTEMPT_CHECK_INTERVAL);
        }
    }
}

function runBaitAttempt(baitAttemptIndex) {
    const baitNode = insertBaitNode();
    checkNodeBlocked(0, baitNode, () => {
        if (document.body.contains(baitNode)) {
            document.body.removeChild(baitNode);
        }
        setCachedResponse(true);
    }, () => {
        if (document.body.contains(baitNode)) {
            document.body.removeChild(baitNode);
        }
        if (baitAttemptIndex === NUM_BAIT_ATTEMPTS) {
             setCachedResponse(false);
        } else {
            setTimeout(() => {
                runBaitAttempt(baitAttemptIndex + 1);
            }, BAIT_ATTEMPT_INTERVAL);
        }
    });
}

export function detectAdBlock() {
    setTimeout(() => runBaitAttempt( 0), INITIAL_DELAY);
}

// Return true, false or null (if we don't have a response yet)
export function isAdBlockEnabled() {
    if (!initializedAdBlockDetection) {
        initializedAdBlockDetection = true;
        detectAdBlock();
    }

    return cachedResponse;
}

