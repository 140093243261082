import {Condition} from "../engine/Condition";

export class StayOnPageCondition extends Condition {
    constructor(...args) {
        super(...args);

        this.stayedOnPage = false;
        this.attachTimeout(() => {
            this.stayedOnPage = true;
            this.requestReEval();
        }, this.options.seconds * 1000);
    }

    evaluate() {
        return this.stayedOnPage;
    }
}
