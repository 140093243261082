import {IFrameElement} from "../elements/IFrameElement";
import {IFRAME_APP_TYPE, IS_PRODUCTION, PANEL_TYPE, DEFAULT_TRANSITION_DURATION_MS, TEST_MODE} from "../../Constants";
import {IFrameMessages} from "../../messaging/IFrameMessages";
import {isSmallScreen} from "../../../blinkpay/UtilsLib";
import {delayThrottled} from "../../utils/Utils";
import {sdkState} from "../../modules/SDKState";
import {WidgetRoutes} from "../../../blinkpay/widget/WidgetRoutes";


const WidgetStyle = {
    widget: {
        border: "none",
        zIndex: 2,
        overflow: "hidden",
        alignSelf: "flex-end",
        position: "absolute",
        height: "100%",
        width: "100%",
        bottom: 0,
        transition: DEFAULT_TRANSITION_DURATION_MS + "ms ease",
    },
    skipTransition: {
        transition: "none",
    },
    expandedMobile: {
        height: "100%",
        left: 0,
        right: 0,
        margin: "0 auto",
    },
    expandedDesktop: {
        left: "",
        right: "",
        margin: "",
    },
    collapsed: {
        transform: "",
        transformOrigin: "",
        left: "",
        pointerEvents: "none",
    },
};

export class Widget extends IFrameElement {
    pointerEventsTimeout = null;
    visibilityTimeout = null;
    skipTransition = false;

    get appType() {
        return IFRAME_APP_TYPE.widget;
    }

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            positionBottom: 0,
            route: WidgetRoutes.INDEX_COLLAPSED,
            expanded: false,
            visible: false,
            activePanel: false,
        };
    }

    updateOptions(options) {
        if (options.positionBottom != null && options.positionBottom !== this.options.positionBottom) {
            this.skipTransition = true;
        }
        super.updateOptions(options);
        if (!IS_PRODUCTION || TEST_MODE) {
            if (options.route) {
                this.node?.setAttribute("route", options.route);
            }
            if (options.activePanel === false) {
                this.node?.setAttribute("panel-type", PANEL_TYPE.empty);
            }
            this.attachTimeout(() => {
                this.node?.setAttribute("expanded", this.options.expanded || this.options.activePanel);
            }, DEFAULT_TRANSITION_DURATION_MS);
        }
    }

    refreshStyle() {
        super.refreshStyle();
        this.setStyle(WidgetStyle.widget);
        if (this.skipTransition) {
            this.setStyle(WidgetStyle.skipTransition);
            this.skipTransition = false;
        }
        clearTimeout(this.pointerEventsTimeout);
        clearTimeout(this.visibilityTimeout);
        if (this.options.activePanel || (this.options.visible && this.options.expanded)) {
            if (isSmallScreen()) {
                this.setStyle(WidgetStyle.expandedMobile);
            } else {
                this.setStyle(WidgetStyle.expandedDesktop);
            }
            this.setStyle("visibility", "visible");
            this.pointerEventsTimeout = this.attachTimeout(() => {
                this.setStyle("pointerEvents", "all");
            }, DEFAULT_TRANSITION_DURATION_MS);
        } else {
            this.setStyle(WidgetStyle.collapsed);
            // All this visibility logic is for a bug on TNR where mobile scrolling on a popup doesn't work because
            // the widget is above it.
            this.visibilityTimeout = this.attachTimeout(() => {
                this.setStyle("visibility", sdkState.hideWallet ? "hidden" : "visible");
            }, DEFAULT_TRANSITION_DURATION_MS);
        }
    }

    onMount() {
        super.onMount();

        const onResize = delayThrottled(() => {
            this.refreshStyle();
            this.sendMessage(IFrameMessages.REPOSITION_WIDGET);
        }, 100);

        this.attachEventListener(window, "resize", onResize);
        this.attachEventListener(window, "orientationchange", onResize);
    }
}
