import {merchantVariableModule} from "./merchant-state/MerchantVariableModule";
import {merchantFunctionModule} from "./merchant-state/MerchantFunctionModule";
import {deepSetAttr, deepCopy, isPlainObject, isString} from "../../stem-core/src/base/Utils";

// We'd need to create a copy of the child element TODO
function cloneContentNode(x) {
    if (isString(x)) return String(x);
    return deepCopy({}, x);
}


function parseForLoopNode(contentNode) {
    contentNode = {...contentNode}; // Make a copy

    let indexName = "index";
    let varName = "entry";

    // Remove fields that are expected
    delete contentNode.tag;
    delete contentNode.children;
    if (contentNode.indexName) {
        indexName = contentNode.indexName;
        delete contentNode.indexName;
    }

    let entriesExpr = contentNode.entries;
    if (!contentNode.entries) {
        const keys = Object.keys(contentNode);
        // Only allow a single field in that case
        if (keys.length !== 1) {
            throw Error("Ambiguous For element. The field 'entries' is required, otherwise there should be only one field");
        }
        varName = keys[0];
        entriesExpr = contentNode[varName];
    }

    // TODO maybe add fields that keep the full array and the 1-based counter
    return {
        entriesExpr,
        indexName,
        varName
    }
}

export class ExprEvaluator {
    constructor(context, includeDefaults = true) {
        if (includeDefaults) {
            context = {
                ...merchantVariableModule.all(),
                ...merchantFunctionModule.all(),
                ...context
            };
        }
        this.context = context;
    }

    eval(expr) {
        if (!this.rawEvaluator) {
            // Simple caching, to not generate what we don't need
            // TODO @cleanup move this function logic inside this class later
            this.rawEvaluator = makeExpressionEvaluator(this.context);
        }
        return this.rawEvaluator(expr);
    }

    // Return a new evaluator with added context
    withExtraContext(extraContext) {
        return new ExprEvaluator({
            ...this.context,
            ...extraContext,
        }, false);
    }

    processPanelNode(contentNode) {
        const tagName = contentNode.tag.toLowerCase();

        if (tagName === "if") {
            const expr = this.eval(contentNode.expr);
            const content = expr ? (contentNode.then || contentNode.children) : contentNode.else;
            return this.evaluateNode(content);
        }

        if (tagName === "for") {
            const {entriesExpr, indexName, varName} = parseForLoopNode(contentNode);
            const entries = this.eval(entriesExpr);

            let result = [];
            for (const [loopIndex, entry] of entries.entries()) {
                const subeval = this.withExtraContext({
                    [varName]: entry,
                    [indexName]: loopIndex,
                });
                for (const child of contentNode.children) {
                    const newElement = subeval.evaluateNode(cloneContentNode(child));
                    result.push(newElement);
                }
            }
            return result;
        }

        return contentNode;
    }

    evaluateNode(contentNode) {
        if (isString(contentNode)) {
            // TODO I think this should return a dynamic object (basically a function that returns this expression
            if (contentNode.startsWith("expr:")) {
                return this.eval(contentNode.substr(5));
            }
            return contentNode.replace(/{{([^{}]*)}}/g, (match, expr) => String(this.eval(expr) ?? ""));
        }
        if (Array.isArray(contentNode)) {
            return contentNode.map((entry) => this.evaluateNode(entry));
        }
        if (!isPlainObject(contentNode)) {
            // Also handles nulls
            return contentNode;
        }

        // Evaluate special nodes
        if (isString(contentNode.tag)) {
            const newNode = this.processPanelNode(contentNode);
            if (newNode !== contentNode) {
                return newNode;
            }
            contentNode = newNode;
        }

        const processedNode = {};
        for (const key of Object.keys(contentNode)) {
            processedNode[key] = this.evaluateNode(contentNode[key]);
        }
        return processedNode;
    }
}

function objectToCodeValue(obj) {
    if (isString(obj)) {
        return obj;
    }
    return "{" + Object.keys(obj).map(key => `"${key}": ${objectToCodeValue(obj[key])}`).join(",") + "}";
}

function makeVarDeclarations(flatObject, keyTransform) {
    // We un-flatten the context variables into an object (e.g. {"namespace.variable": 5}
    // becomes {namespace: {variable: 5}}) so expressions such as "namespace.variable >= 4"
    // work well when executed using eval or new Function(code).
    const unFlattenedObject = {};

    // Sorted in ascending order by length so if a key is a prefix of another, the prefix comes first.
    // This way, if the object contains both "a" and "a.b", "a.b" will be applied after "a".
    const ascendingLengthOrdering = (a, b) => a.length - b.length;

    const flatKeys = Object.keys(flatObject).sort(ascendingLengthOrdering);

    for (const flatKey of flatKeys) {
        const keychain = flatKey.split(".");
        deepSetAttr(unFlattenedObject, keychain, keyTransform(flatKey));
    }
    // Stringify the unFlattenedObject
    const keys = Object.keys(unFlattenedObject).sort(ascendingLengthOrdering);
    return keys.map(key => `${key} = ${objectToCodeValue(unFlattenedObject[key])}`);
}

export function makeExpressionEvaluator(variableValues) {
    const variableDeclarations = makeVarDeclarations(variableValues, key => `Blink$Variables["${key}"]`);
    let functionPreamble ="'use strict';\n";
    if (variableDeclarations.length > 0) {
        functionPreamble += `var ${variableDeclarations.join(",\n  ")};\n`;
    }
    return (expr) => {
        const functionCode = functionPreamble + "return " + expr;
        // This uses a Function constructor instead of eval, to not expose any internals of the
        // SDK to the merchant-provided function. It still acts as a global function (has access
        // to "window" and the publisher's JS runtime).
        const tempFunc = new Function("Blink$Variables", functionCode);
        try {
            return tempFunc(variableValues);
        } catch (error) {
            merchantFunctionModule.handleException(error, null, functionCode);
            return null;
        }
    };
}

export function evaluateTemplate(template, context) {
    const evaluator = new ExprEvaluator(context);

    return evaluator.evaluateNode(template);
}
