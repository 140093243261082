import {BLINK_PAY_URL} from "../Constants";
import {BLINK_MESSAGE_IDENTIFIER} from "./IFrameMessages";
import {Dispatchable} from "../../stem-core/src/base/Dispatcher";

// Transforms the basic window message events between iframe and main page
// into type-defined events identified by their source.
class SDKMessagingChannelClass extends Dispatchable {
    constructor() {
        super();

        this.attachEventListener(window, "message", event => {
            // Since the listener is on window, the actual events that are targeted for this connection must be
            // identified. Some window message messages from the BlinkPay website are made by the social auth
            // buttons, and their data property is not an object, so they need to be filtered.
            if (event.origin !== BLINK_PAY_URL || typeof event.data !== "object") {
                return;
            }
            const {data} = event;
            const {appType, type} = data;

            if (!(typeof type === "string" || type instanceof String) || !type.startsWith(BLINK_MESSAGE_IDENTIFIER)) {
                return;
            }

            const iFrame = [...document.getElementsByTagName("iframe")].find(node => node.contentWindow === event.source);

            const payload = {
                ...data,
                type: data.type.substring(BLINK_MESSAGE_IDENTIFIER.length),
                origin: event.origin,
            };

            this.dispatch(payload.type, payload, iFrame, appType);
        });
    }
}

export const iframeToSdkChannel = new SDKMessagingChannelClass();
