import {Condition} from "../engine/Condition";
import {isMobileDevice} from "../../../../stem-core/src/base/Device";
import {toArray} from "../../../../stem-core/src/base/Utils.js";

const Device = {
    MOBILE: "mobile",
    DESKTOP: "desktop",
};

function getDeviceType() {
    // TODO: Add support for TABLET detection, separate from MOBILE.
    if (isMobileDevice()) {
        return Device.MOBILE;
    }
    return Device.DESKTOP;
}

// TODO: Implement options for os & browser.
/** @property {{
 *      device: string|string[]|undefined,
 *  }} options */
export class UserAgentCondition extends Condition {
    evaluate() {
        let result = true;
        if (this.options.device) {
            result &&= toArray(this.options.device).indexOf(getDeviceType()) !== -1;
        }
        return result;
    }
}
