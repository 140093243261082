export const BLINK_MESSAGE_IDENTIFIER = "blinkPay-";

// TODO @branch do we really need all these?
export const IFrameMessages = {
    //
    // Iframe to SDK messages
    //
    UPDATE_SDK_USER_DATA: "updateSdkUserData",
    // We use this for both sending the public data from the sdk and also marking Connection endpoint as loaded
    CREATE_IFRAME_APP: "createIframeApp",
    IFRAME_HTML_LOADED: "iFrameHtmlLoaded",
    REQUEST_AMAZON_PAY: "requestAmazonPay",

    // Wallet state
    ROUTE_CHANGE: "routeChange",
    TRANSITION_CHANGE: "transitionChange",
    SHOW_AUTOPAY: "showAutopay",
    START_FLOW_REQUEST: "startFlowRequest",

    // Panel state
    UPDATE_PANEL_SIZE: "updatePanelSize",
    REQUEST_UPDATE_PANEL_SIZE: "requestUpdatePanelSize", // Sent by SDK TODO seems never to be handled by the iframe
    UPDATE_PANEL_CONTENT: "updatePanelContent", // Sent from the SDK
    CHECK_FLOW_SUCCESS: "checkFlowDone",
    SCROLL_IFRAME_TOP: "scrollIframeTop",
    DESTROY_PANEL: "destroyPanel",

    // Auth
    USER_TOKEN_UPDATE: "userTokenUpdate",
    REDIRECT_TO_URL: "redirectToUrl",
    SET_MERCHANT_TOKEN: "setMerchantToken",
    UPDATE_MERCHANT_TOKEN: "updateMerchantToken",

    // Payments
    SESSION_PAYMENT_MADE: "sessionPaymentMade", // Happens when a payment in specifically this tab was made.
    MAKE_PAYMENT_VIA_SDK: "makePaymentViaSDK",
    MAKE_NEXT_PAYMENT_MANUALLY: "makeNextPaymentManually",

    // TODO remove it
    WIDGET_DATA_LOADED: "widgetDataLoaded", // The iframe has working JS and state

    //
    // IFrame-to-IFrame messages
    //
    UPDATE_GLOBAL_STATE: "updateGlobalState",

    //
    // SDK to Iframe messages
    //
    // Analytics
    // Sent by the SDK to iframes when environment or SDK identity data changes.
    UPDATE_ANALYTICS_DATA_FROM_SDK: "updateAnalyticsDataFromSDK",
    AMAZON_PAY_EVENT: "amazonPayEvent",

    // Payments
    PAYMENT_REQUEST: "paymentRequest",
    PAYMENT_RESPONSE: "paymentResponse",

    // Wallet state
    UPDATE_APP_STATE: "updateAppState",
    REPOSITION_WIDGET: "repositionWidget",

    // Analytics
    ANALYTICS_EVENT: "analyticsEvent",
    FLOW_CHECKPOINT: "flowCheckpoint", // TODO The fact that analytics are only sent by the iframe might be the reason they're often blocked

    // TODO @branch this might not be final
    EDIT_MERCHANT_USER_METADATA: "editMerchantUserMetadata",
};

// Since there can be multiple iframes that use this class, we identify the messages for this specific target by
// adding the identifier to the type of the message. A similar logic is made inside the iframe website.
export function formatType(type) {
    return BLINK_MESSAGE_IDENTIFIER + type;
}
