import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";

export function removeScrollOnScrim() {
    const element = document.documentElement.clientHeight !== document.documentElement.scrollHeight ? document.documentElement : document.body;
    const scrollbarWidth = window.innerWidth - element.clientWidth;
    const documentComputedStyle = getComputedStyle(element);
    const initialRightPadding = documentComputedStyle.getPropertyValue("padding-right");

    const initialDocumentStyle = {
        overflow: documentComputedStyle.getPropertyValue("overflow"),
        paddingRight: initialRightPadding,
    };

    if (initialDocumentStyle.overflow.endsWith("hidden")) {
        return {
            scrollbarWidth,
            restore: NOOP_FUNCTION,
        }
    }

    Object.assign(element.style, {
        overflow: "hidden",
        paddingRight: `${parseFloat(initialRightPadding) + scrollbarWidth}px`
    });
    return {
        scrollbarWidth,
        restore: () => {
            Object.assign(element.style, initialDocumentStyle)
        },
    };
}
