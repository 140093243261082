import {Dispatchable} from "../../../stem-core/src/base/Dispatcher";
import {IS_PRODUCTION, TEST_MODE} from "../../Constants";
import {delayThrottled} from "../../utils/Utils";


// TODO @cleanup This Stem-like garbage is garbage
export class NodeElement extends Dispatchable {
    constructor(options={}, node=null) {
        super();

        this.options = {...this.getDefaultOptions(), ...options};
        this.node = node || document.createElement(this.options.nodeType);
        if (!IS_PRODUCTION || TEST_MODE) {
            if (this.options.testId) {
                this.node.setAttribute("test-id", this.options.testId);
            }
        }
        // TODO: Enable something similar to this at some point. This protects us from merchants' global styles
        //  affecting our components.
        // this.node.style.all = "unset";
    }

    getDefaultOptions() {
        return {
            nodeType: "div",
            style: {},
        };
    }

    updateOptions(options) {
        Object.assign(this.options, options);
        this.refreshStyle();
        if (!IS_PRODUCTION || TEST_MODE) {
            if (this.node && options.testId) {
                this.node.setAttribute("test-id", options.testId);
            }
        }
    }

    refreshStyle() {
        this.setStyle(this.options.style);
    }

    mount(parentNode, index=null) {
        if (index == null) {
            parentNode.appendChild(this.node);
        } else {
            parentNode.insertBefore(this.node, parentNode.children?.[index]);
        }
        this.refreshStyle();
        this.onMount();
    }

    setStyle(key, value) {
        if (!this.node) {
            return;
        }
        if (typeof key === "object") {
            Object.assign(this.node.style, key);
        } else {
            this.node.style[key] = value;
        }
    }

    // TODO: Rename this method
    destroyNode() {
        this.cleanup();
        this.node?.remove();
        delete this.node;
    }

    onMount() {
        this.attachEventListener(window, "resize", delayThrottled(() => this.refreshStyle(), 100));
    }
}
