import {GateElement, GateStyle} from "../elements/GateElement";

const PreviewGateStyle = {
    gate: {
        ...GateStyle.gate,
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        boxSizing: "border-box",
        width: "100%",
        top: 0,
        left: 0,
    },
};

export class PreviewGate extends GateElement {
    styleSheet = PreviewGateStyle;

    constructor(...args) {
        super(...args);

        this.preview = document.createElement("div");
        this.node.appendChild(this.preview);

        if (this.options.previewFadeOut) {
            this.fadeOut = document.createElement("div");

            Object.assign(this.fadeOut.style, {
                background: `linear-gradient(rgba(255,255,255,0),${this.options.previewFadeOutToColor})`,
                height: this.options.previewFadeOutHeight + "px",
            });

            this.node.appendChild(this.fadeOut);
        }
    }

    refreshStyle() {
        super.refreshStyle();
        Object.assign(this.preview.style, {
            height: this.options.previewHeight + "px",
        });
        this.setStyle(this.options.customStyle ?? {});
    }

    getPanelStyle() {
        return {
            ...super.getPanelStyle(),
            background: this.options.previewFadeOutToColor,
        };
    }

    computeParentStyle() {
        const height = (this.preview?.offsetHeight || 0) +
            (this.fadeOut?.offsetHeight || 0) + (this.panel?.height || 0) + "px";

        return {
            ...super.computeParentStyle(),
            overflow: "hidden",
            minHeight: height,
            maxHeight: height,
            height
        };
    }
}
