import {IFrameMessages} from "../messaging/IFrameMessages";
import {merchantLocalStorage, merchantSessionStorage} from "./merchant-state/MerchantStorage";
import {generateUniqueId} from "../../blinkpay/UtilsLib";
import {EventSender, AnalyticsClient} from "../utils/AnalyticsClient";
import {MERCHANT_STORAGE_KEYS} from "../Constants";
import {makeUniqueSelector} from "../utils/SelectorCalculator.js";
import {onCurrentAndFuturePageAccess} from "../utils/PageAccessHelpers";
import {allIFrameElements, onNewIFrameElementMounted} from "../ui/elements/IFrameElement";
import {loadReferrerUrl} from "../utils/Referrer";
import {userDataModule} from "./UserDataModule";
import {
    getMerchant,
    getMerchantId,
    isMerchantActive,
    onPublicDataLoaded
} from "./PublicData";
import {Dispatchable, Dispatcher} from "../../stem-core/src/base/Dispatcher";
import {iframeToSdkChannel} from "../messaging/IframeToSdkChannel";

class AnalyticsModule {
    allEventsDispatcher = new Dispatcher();
    eventsByTypeDispatcher = new Dispatchable();
    shouldStoreCookies = null; // Don't initialize the cookie storage yet.
    client = new AnalyticsClient(EventSender.sdk);

    init() {
        onCurrentAndFuturePageAccess(() => this.updateEnvironment(this.getDefaultEnvironment()));

        this.initDispatchers();

        onPublicDataLoaded(() => {
            if (!isMerchantActive()) {
                return;
            }
            const merchantId = getMerchantId();
            if (!merchantId) {
                return;
            }
            const identity = {
                merchantId,
            };
            const merchant = getMerchant();
            if (merchant && merchant.alias) {
                identity.merchantAlias = merchant.alias;
            }
            this.client.updateIdentity(identity);
            this.broadcastEnvironment();
        });

        onNewIFrameElementMounted.addListener((iframeElement) => {
            iframeElement.sendMessage(IFrameMessages.UPDATE_ANALYTICS_DATA_FROM_SDK, {
                environment: this.client.environment,
                identity: this.client.identity,
                iframeSelector: makeUniqueSelector(iframeElement.node),
            });
        });

        userDataModule.authenticationChange.addListener(() => {
            if (userDataModule.userData.userId) {
                this.client.identity.userId = userDataModule.userData.userId;
            } else {
                delete this.client.identity.userId;
            }
            this.broadcastEnvironment();
        })
    }

    setEnableStorage(value) {
        // TODO @tier2 enabling analytics should be configurable per merchant
        if (value === this.shouldStoreCookies) {
            return;
        }
        this.shouldStoreCookies = value;
        if (value) {
            // Force load the analytics identifiers from storage.
            this.localId = merchantLocalStorage.getItem(MERCHANT_STORAGE_KEYS.merchantLocalId) || this.localId;
            this.sessionId = merchantSessionStorage.getItem(MERCHANT_STORAGE_KEYS.merchantSessionId) || this.sessionId;
        } else {
            delete this.localId;
            delete this.sessionId;
        }
        this.initIdentity();
        this.broadcastEnvironment(); // TODO @mustfix this shouldn't be done manually here
    }

    initIdentity() {
        if (this.shouldStoreCookies) {
            this.localId = this.localId || merchantLocalStorage.getItem(MERCHANT_STORAGE_KEYS.merchantLocalId);
            if (!this.localId) {
                this.localId = generateUniqueId();
                merchantLocalStorage.setItem(MERCHANT_STORAGE_KEYS.merchantLocalId, this.localId);
            }

            this.sessionId = this.sessionId || merchantSessionStorage.getItem(MERCHANT_STORAGE_KEYS.merchantSessionId);
            if (!this.sessionId) {
                this.sessionId = generateUniqueId();
                merchantSessionStorage.setItem(MERCHANT_STORAGE_KEYS.merchantSessionId, this.sessionId);
            }
        }
        // TODO @branch don't we also put in a Blink third party identity?
        this.client.updateIdentity({
            localId: this.localId,
            sessionId: this.sessionId,
        });
    }

    broadcastEnvironment() {
        allIFrameElements.forEach(iFrameElement => {
            iFrameElement.sendMessage(IFrameMessages.UPDATE_ANALYTICS_DATA_FROM_SDK, {
                identity: this.client.identity,
                environment: this.client.environment,
            });
        });
    }

    getDefaultEnvironment() {
        return {
            referrerURL: loadReferrerUrl(),
            url: window.location.href,
        }
    }

    updateEnvironment(data, broadcast = true) {
        this.client.updateEnvironment(data);
        if (broadcast) {
            this.broadcastEnvironment();
        }
    }

    initDispatchers() {
        // Forward analytics events sent from the SDK's AnalyticsClient
        this.client.addListener("event", (event) => {
            this.allEventsDispatcher.dispatch(event);
            this.eventsByTypeDispatcher.dispatch(event.type, event);
        });

        // Forward analytics events sent from the IFrames' AnalyticsClient
        iframeToSdkChannel.addListener(IFrameMessages.ANALYTICS_EVENT, ({event}) => {
            this.allEventsDispatcher.dispatch(event);
            this.eventsByTypeDispatcher.dispatch(event.type, event);
        });
    }
}

export const analyticsModule = new AnalyticsModule();
