import {
    BLINK_API_URL,
    BLINK_VERSION_URL_PARAM,
    PUBLIC_DATA_BASE_URL,
} from "../Constants";
import {markPageLoadTiming, PAGE_LOAD_TIMING_MARKS} from "../utils/PerformanceTiming.js";
import {getQueryParam} from "../../blinkpay/UtilsLib";
import {OnceDispatcher} from "../../stem-core/src/base/Dispatcher.js";

let merchantId = null;
let publicData = null;
export const publicDataLoaded = new OnceDispatcher();

export async function fetchPublicData(clientId) {
    const versionParam = getQueryParam(BLINK_VERSION_URL_PARAM);
    const requestArgs = versionParam ? (clientId + "." + versionParam) : clientId;
    const apiEndpoint = PUBLIC_DATA_BASE_URL || BLINK_API_URL;
    const publicDataURL = apiEndpoint + "/public_data." + requestArgs + ".json";

    try {
        const response = await fetch(publicDataURL);
        publicData = await response.json();
    } catch (e) {
        console.error("[BlinkSDK] Failed to load settings");
        return;
    }

    const merchantStore = getPublicDataStore("Merchant", null);
    // Intentionally use == instead of === because the
    // numeric ID might be a string (if it comes from the URL)
    merchantId = merchantStore.find(merchant => merchant.id == clientId || merchant.alias == clientId)?.id;
    if (!merchantId) {
        console.error("[BlinkSDK]: Unrecognized clientId or invalid settings version:", requestArgs);
        return;
    }
    markPageLoadTiming(PAGE_LOAD_TIMING_MARKS.publicData);
    publicDataLoaded.dispatch(publicData);
}

// Always use this, since the backend does not guarantee casing of store names
export function getPublicDataStore(objectType, merchantIdField = "merchantId") {
    for (const key of Object.keys(publicData && publicData.state || {})) {
        if (key.toLowerCase() === objectType.toLowerCase()) {
            const store = publicData.state[key] || [];
            // TODO @Mihai: Skip filtering by merchantIdField
            if (merchantIdField != null) {
                return store.filter(obj => obj[merchantIdField] == null || obj[merchantIdField] === merchantId);
            }
            return store;
        }
    }
    return [];
}

export function onPublicDataLoaded(callback) {
    return publicDataLoaded.addListenerOnce(callback);
}

export function getMerchantId() {
    return merchantId;
}

export function getPublicData() {
    return publicData;
}

export function getMerchant() {
    return getPublicDataStore("Merchant", "id")[0] || null;
}

export function isMerchantActive() {
    return getMerchant()?.sdkStatus === "active";
}

export function getDefaultSDKOptions() {
    const settings = getPublicDataStore("MerchantSDKSettings")[0];
    return settings?.options || {};
}

export function getMerchantUserJourney(aliasOrId) {
    return getPublicDataStore("MerchantUserJourney").find(uj => uj.id == aliasOrId || uj.alias == aliasOrId);
}

export function getMerchantPanels() {
    return getPublicDataStore("MerchantPanel");
}

export function getMerchantPanel(aliasOrId) {
    return getMerchantPanels().find(panel => panel.id == aliasOrId || panel.alias == aliasOrId);
}

export function getMerchantAudience(nameOrId) {
    return getPublicDataStore("MerchantAudience").find(audience => audience.id == nameOrId || audience.name == nameOrId);
}

export function getMerchantFacebookPixel() {
    return getPublicDataStore("MerchantFacebookPixel")[0];
}
