import {Dispatchable} from "../../stem-core/src/base/Dispatcher";

class SDKState extends Dispatchable {
    preferredEmail = null;
    allowUserToCoverFees = false;
    hideWallet = false;

    update(obj) {
        Object.assign(this, obj);
        this.dispatchChange(obj);
    }
}

export const sdkState = new SDKState();
