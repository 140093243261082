import {
    GATE_COVER_PANEL_POSITION,
    GATE_MOUNT_TYPE,
    PANEL_FRAME,
    PANEL_TYPE,
    SOCIAL_APPS
} from "./Constants";
import {getMerchant, getMerchantPanel, getMerchantPanels} from "./modules/PublicData.js";
import {merchantVariableModule} from "./modules/merchant-state/MerchantVariableModule.js";
import {isString, deepCopy} from "../stem-core/src/base/Utils.js";
import {convertHTMLStringToJSON} from "../blinkpay/UtilsLib.js";
import {evaluateTemplate} from "./modules/TemplateEvaluation.js";

const DEFAULT_PANEL_OPTIONS = {
    // The aliasOrId of a panel that this one inherits from. Because of versioning, it's highly recommended
    // using an alias here (aliases are stable across versions, IDs are not).
    parentPanel: null,

    type: PANEL_TYPE.custom,

    // TODO @Mihai this should only be null or array of id/alias
    // Ids or aliases of the subscription offer(s) to use in this panel. If not provided,
    // all subscription offers with visibility=PUBLIC are used.
    subscriptionOffer: null,

    // Ids or aliases of the donation offer(s) to use in this panel. If not provided,
    // all donation offers with visibility=PUBLIC are used.
    // Note: This is for future-compatibility for when we actually support multiple donation offers.
    donationOffer: null,

    // Ids or aliases of the public newsletter(s) to use in this panel. If not provided,
    // all public newsletters are displayed.
    newsletter: null,

    // Ids or aliases of the product(s) to use in this panel. If not provided,
    // all products with visibility=PUBLIC are used.
    products: null,

    // Whether to skip the first panel (the one that's fully merchant customizable)
    // and go straight into the flow.
    skipCTA: false,

    // Whether to enter the flow of editing an active recurring payment instead of creating a new
    // one. This only applies for subscribe & donation flows.
    editRecurring: false,

    // A list of tabs to display in the Inline User Dashboard. The default is to display tabs
    // for all functionality the merchant has enabled.
    dashboardTabs: null,

    // TODO @cleanup rename to authProviders
    // A list of auth providers to enable on the authentication page for this panel.
    authSocialProviders: [SOCIAL_APPS.Facebook, SOCIAL_APPS.Google, SOCIAL_APPS.Twitter, SOCIAL_APPS.LinkedIn],

    // Details about the payment. Required if type is "payment", ignored otherwise.
    // paymentDetails: {
    //   resourceId: String,
    //   productId?: Number,
    //   amount?: Number | String, // If string, means whole units of currency, will be rounded to not have float errors.
    //                             // Will be implied from the product
    //   currency?: String, // ISO code, default to the merchant's currency
    //   comment?: Object,
    // }
    paymentDetails: null,

    // URL of the logo to display at the top of the panel. If this is not
    // provided, no logo is displayed. The URL must be an absolute path
    // to an HTTPS resource.
    logoSrc: null,

    // The ALT text to be applied to the logo image.
    logoAlt: "",

    // Style of the logo displayed at the top of the panel. It supports the format of a Stem styleRule.
    logoStyle: {},

    // Text displayed at the top of the panel. If this is left null, the
    // default differ based on panel type, subscription/donation offer etc.
    text: null,

    // Base style of the text section. It supports the format of a Stem styleRule.
    textStyle: {},

    // Some panels have titles that can be overwritten.
    title: null,

    // Text displayed in the panel button.
    buttonText: "Continue",

    // Base style of the button. It supports the format of a Stem styleRule.
    actionButtonStyle: {},

    // Array of font faces.
    // fonts: [{
    //     family: String,
    //     src: String,
    //     style?: String,
    //     weight?: Number
    //     unicodeRange?: String,
    // }]
    fonts: [],

    // Overrides for Theme properties, whitelisted in MERCHANT_STYLE_WHITELIST
    theme: {},

    // Overrides for the Messages object values.
    messages: {},

    // TODO @version rename "metadata" in panels to conversionMetadata
    // Key-value metadata to be saved for conversions (e.g. newsletter
    // registrations, donations, subscriptions etc.)
    metadata: {},

    content: null,

    context: null,

    frame: PANEL_FRAME.logo,

    // Supported values are available in the GATE_TYPE enum.
    gateType: null,

    // Extra style to apply to the gate element itself.
    customStyle: {},

    // Extra style to apply to the base panel.
    basePanelStyle: {},

    // Extra style to apply to the cancel button.
    // TODO @cleanup bullshit
    cancelButtonCustomStyle: {},

    // Height (in px) of the preview area. Only applicable for type
    // "preview".
    previewHeight: 200,

    // Enable a fade-out effect between the content and the panel. Only
    // applicable for type "preview".
    previewFadeOut: true,

    // Height (in px) of the fade-out zone of the preview area. Only
    // applicable for type "preview", if "previewFadeOut" is enabled.
    previewFadeOutHeight: 100,

    // Color of the bottom of the fade-out area. Only applicable for type
    // "preview", if "previewFadeOut" is enabled.
    previewFadeOutToColor: "white",

    // Color of the cover effect of the gate. Only applicable for type
    // "cover".
    coverColor: "rgba(255, 255, 255, 0.9)",

    // Position of the panel with regards to the gate (which covers the
    // whole area of the content in this case). Only applicable for type
    // "cover".
    coverPanelPosition: GATE_COVER_PANEL_POSITION.center,

    mountType: GATE_MOUNT_TYPE.lastChild,

    // Control if the flow can be closed by the user
    // TODO this is an experiment in defining options
    get disableUserClose() {
        return this.inlineFlow;
    },

    scrimClickCollapse: true,

    // Some flows can be done inline on the page instead of in a pop-up
    inlineFlow: false,

    // Dictates the conditions on which the panel should close itself
    flowSuccessType: null,

    // A string that can be used to pre-populated the discount code field
    selectedDiscountCode: null,

    // The subscription offer that the user populates the flow for
    selectedSubscriptionOfferId: null,

    // What do push when there's a success panel
    onSuccessCrossSell: null,

    // If the discount code input should be disabled. By default it's shown if there is an active code.
    hideDiscountCodeInput: false,

    // TODO: Add scrimBlockScroll

    // Callbacks

    onMount: null,

    onFlowCheckpoint: null,

    onFlowSuccess: null,

    onFlowFinish: null,
};

const DEFAULT_PANEL_OPTIONS_PER_TYPE = {
    [PANEL_TYPE.subscribe]: {
        content: `<div style="padding: 20px 30px;text-align: center;">
            <Text>You need to be a subscriber to access this content.</Text>
            <SubscribeButton label="Subscribe"></SubscribeButton>
            <LoginFooter label="Already a subscriber?"></LoginFooter>
            <PoweredByBlink></PoweredByBlink>
        </div>`,
    },
    // TODO @cleanup can we fix this with the subscription panel?
    [PANEL_TYPE.giftSubscription]: {
        content: `<div style="padding: 20px 30px;text-align: center;">
        <Text>Gift a subscription to someone you love!</Text>
        <GiftSubscriptionButton label="Gift a subscription"></GiftSubscriptionButton>
        <PoweredByBlink></PoweredByBlink>
    </div>`,
    },
    [PANEL_TYPE.donation]: {
        content: `<div style="padding: 20px 30px;text-align: center;">
            <Text><strong>{{publisher_name}}</strong> relies on support from readers like yourself. Even a small amount would help us improve our coverage.</Text>
            <DonateButton label="Donate"></DonateButton>
            <LoginFooter label="Already a donor?"></LoginFooter>
            <PoweredByBlink></PoweredByBlink>
        </div>`,
    },

    // TODO: The credit value should be taken from the public data.
    [PANEL_TYPE.payment]: {
        content: `<div style="padding: 20px 30px;text-align: center;">
        <Text>{{publisher_name}} uses Blink to allow access to individual articles.</Text>
        <PurchaseButton></PurchaseButton>
        <If expr="!blinkSDK.isAuthenticated()">
            <Text>Get <b>$2</b> credit when you create an account.</Text>            
        </If>
        <LoginFooter label="Already purchased?"></LoginFooter>
        <PoweredByBlink></PoweredByBlink>
    </div>`,
    },

    [PANEL_TYPE.newsletter]: {
        content: `<div style="padding: 20px 30px;text-align: center;">
        <Text>Subscribe to our newsletter to keep yourself updated with our latest content.</Text>
        <NewsletterButton></NewsletterButton>
        <PoweredByBlink></PoweredByBlink>
    </div>`,
    },
}

// Apply one layer of panel options on top of target options. This modifies the targetOptions
// in-place in addition to returning them, similar to Object.assign.
function overwritePanelOptions(targetOptions, layerOptions) {
    if (!layerOptions) {
        return;
    }
    // Fonts, theme constants, messages, metadata and contexts are merged between the options.
    Object.assign(targetOptions, layerOptions, {
        fonts: [
            ...(targetOptions.fonts || []),
            ...(layerOptions.fonts || []),
        ],
        theme: {
            ...targetOptions.theme,
            ...layerOptions.theme,
        },
        messages: {
            ...targetOptions.messages,
            ...layerOptions.messages,
        },
        metadata: {
            ...targetOptions.metadata,
            ...layerOptions.metadata,
        },
        context: {
            ...targetOptions.context,
            ...layerOptions.context,
        },
    });
}

// TODO @cleanup move this globally
// store can be a Store or and Array, anything with a .find()
export function findByIdOrAlias(store, idOrAlias) {
    return store.find(obj => obj.id == idOrAlias || obj.alias == idOrAlias);
}

// Build the panel options based on inheritance rules and template evaluation.
// Only panels in merchantPanels are used for inheritance.
export function composePanelOptions(givenPanelOptions, merchantPanels, context={}) {
    const panelOptions = {};

    overwritePanelOptions(panelOptions, DEFAULT_PANEL_OPTIONS);

    // TODO @cleanup this is not the best
    const merchant = getMerchant();
    if (merchant) {
        overwritePanelOptions(panelOptions, {
            context: {
                publisher_name: merchant.name,
                merchant_name: merchant.name,
            }
        })
    }

    const panelInheritenceChain = [];

    let panelId = givenPanelOptions.panel;
    while (panelId != null) {
        const panel = findByIdOrAlias(merchantPanels, panelId);
        if (panel == null) {
            break;
        }
        panelInheritenceChain.push(panel);
        panelId = panel.options.parentPanel;
    }

    // If we didn't find any panels in the inheritance chain, the "panel" option may be a panel
    // type instead. This behaviour is here to support legacy journeys, but it may not be so bad to
    // keep this fallback even if we fix those.
    if (panelInheritenceChain.length === 0 && !givenPanelOptions.type && Object.values(PANEL_TYPE).indexOf(givenPanelOptions.panel) >= 0) {
        givenPanelOptions.type = givenPanelOptions.panel;
        delete givenPanelOptions.panel;
    }

    // Beyond the inheritance chain defined by the panel, there are two "special" panels we inherit from:
    // the base panel of our type and the global base panel.
    const panelType = givenPanelOptions.type || panelInheritenceChain.find(panel => panel?.options?.type)?.options?.type;
    const basePanelOfType = findByIdOrAlias(merchantPanels, panelType);
    if (panelType !== PANEL_TYPE.custom && basePanelOfType) {
        panelInheritenceChain.push(basePanelOfType);
    }

    // Defaults per panel type
    const defaultTypeOptions = DEFAULT_PANEL_OPTIONS_PER_TYPE[panelType];
    if (defaultTypeOptions) {
        panelInheritenceChain.push({options: defaultTypeOptions});
    }

    const basePanel = findByIdOrAlias(merchantPanels, "base");
    if (basePanel) {
        panelInheritenceChain.push(basePanel);
    }

    const mainPanel = panelInheritenceChain[0];

    // Keep track of these options for analytics
    overwritePanelOptions(panelOptions, {
        metadata: {
            panelId: mainPanel?.id,
            panelAlias: mainPanel?.alias || panelOptions.type, // For a while, this might be a non-existing panel
        }
    });

    for (const layer of panelInheritenceChain.reverse()) {
        overwritePanelOptions(panelOptions, layer.options);
    }

    overwritePanelOptions(panelOptions, {
        context,
    });
    overwritePanelOptions(panelOptions, givenPanelOptions);

    if (!panelOptions.mountType) {
        // TODO @cleanup this needs to be in some default gate options
        panelOptions.mountType = panelOptions.gateType ? GATE_MOUNT_TYPE.lastChild : GATE_MOUNT_TYPE.replaceChildren;
    }

    if (window.isInPreviewMode) {
        // TODO @cleanup a bit of a hack here
        panelOptions.isInPreviewMode = true;
    }

    return panelOptions;
}


function evaluateNodeAnalyticsOptions(options) {
    for (const key of Object.keys(options)) {
        if (key.startsWith("analytics-")) {
            // Add these as data- to the node, to be accessed later through node.dataset
            options["data-" + key] = options[key];
        }
    }
    return options;
}

export function evaluatePanelContent(options) {
    // TODO @flow2 @cleanup "content" is the worst possible name, can't find anything
    if (isString(options.content)) {
        options.contentAST = convertHTMLStringToJSON(options.content, evaluateNodeAnalyticsOptions);
    } else {
        options.contentAST = deepCopy({}, options.content);
    }
    options.contentAST = evaluateTemplate(options.contentAST, options.context);
}

// TODO @branch reevaluate how this is used all over the place
export function evaluatePanelOptions(options, merchantPanels = getMerchantPanels(), context = merchantVariableModule.all()) {
    options = composePanelOptions(options, merchantPanels, context);

    evaluatePanelContent(options);

    // Now also evaluate the other panel options
    for (const key of Object.keys(options)) {
        // TODO @branch this shouldn't happen in the iframe at all, ensure it never does
        if (key === "context" || key === "parentPanel" || key === "content" || key === "contentAST") {
            // Some entries shouldn't be evaluated, like the original content.
            continue;
        }

        options[key] = evaluateTemplate(options[key], options.context);
    }

    return options;
}

export function editPanel(aliasOrId, options) {
    if (Array.isArray(aliasOrId)) {
        for (const panel of aliasOrId) {
            editPanel(panel, options);
        }
        return;
    }
    const panel = getMerchantPanel(aliasOrId);

    // Edit the object in the store directly
    if (panel) {
        panel.options = panel.options || {};
        overwritePanelOptions(panel.options, options);
    }
}
