import {Condition} from "../engine/Condition";

export class AndCondition extends Condition {
    evaluate() {
        let haveNull = false;
        for (const subCondition of this.ops) {
            const result = subCondition.evaluate();
            if (result === false) {
                return false;
            }
            haveNull = haveNull || (result == null);
        }
        return haveNull ? null : true;
    }
}

export class OrCondition extends Condition {
    evaluate() {
        let haveNull = false;
        for (const subCondition of this.ops) {
            const result = subCondition.evaluate();
            if (result) {
                return true;
            }
            haveNull = haveNull || (result == null);
        }
        return haveNull ? null : false;
    }
}

export class NotCondition extends Condition {
    evaluate() {
        const childResult = this.ops[0].evaluate();
        return (childResult == null) ? null : !childResult;
    }
}

export class TrueCondition extends Condition {
    evaluate() {
        return true;
    }
}
