// TODO @flow WTF is this crap??
import {iframeToSdkChannel} from "../messaging/IframeToSdkChannel";
import {IFrameMessages} from "../messaging/IFrameMessages";
import {Dispatcher} from "../../stem-core/src/base/Dispatcher";
import {walletModule} from "./WalletModule";
import {INSUFFICIENT_FUNDS_ERROR_CODE} from "../Constants";
import {WidgetRoutes} from "../../blinkpay/widget/WidgetRoutes";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";


class PurchaseModule {
    paymentRequestReceived = new Dispatcher();
    paymentRequestCompleted = new Dispatcher();

    /** @type {Map<string|number, {request: object, error: object|undefined, response: object|undefined}>}*/
    paymentRequests = new Map();

    paymentCallbacks = new Map();

    // Pending payment request. Only one such request will be pending and will execute once user logs in.
    // TODO @flow make a single pending Request, not an array
    pendingRequests = [];

    lastPaymentRequestData = {};

    init() {
        iframeToSdkChannel.addListener(IFrameMessages.MAKE_PAYMENT_VIA_SDK, ({request}) => this.requestPayment(request));

        iframeToSdkChannel.addListener(IFrameMessages.PAYMENT_RESPONSE, ({request, response, error}) => {
            const offerCallbacks = this.paymentCallbacks.get(request.offerId) || {onSuccess: [], onError: []};
            if (response) {
                this.paymentRequests.set(request.offerId, {request, response});
                offerCallbacks.onSuccess.forEach(callback => callback(response));
                offerCallbacks.onSuccess = [];
            } else {
                this.paymentRequests.set(request.offerId, {request, error});
                offerCallbacks.onError.forEach(callback => callback(error));
                offerCallbacks.onError = [];
                if (request.offerId === this.lastPaymentRequestData.offerId && error.code === INSUFFICIENT_FUNDS_ERROR_CODE) {
                    walletModule.changeRoute(WidgetRoutes.INSUFFICIENT_FUNDS);
                }
            }
            this.paymentRequestCompleted.dispatch();
        })
    }

    resolvePendingRequests() {
        const pendingRequests = this.pendingRequests;
        this.pendingRequests = null;
        for (const args of pendingRequests) {
            this.requestPayment(...args);
        }
    }

    requestPayment(request, callback = NOOP_FUNCTION, errorCallback = NOOP_FUNCTION) {
        // TODO @pay @branch not more than one pending request. If we haven't send the other one just drop it.
        if (this.pendingRequests != null) {
            this.pendingRequests.push(arguments);
            return;
        }

        this.lastPaymentRequestData = request;
        this.paymentRequests.set(request.offerId, {request});
        const paymentCallbacksForOffer = this.paymentCallbacks.get(request.offerId) || {
            onSuccess: [],
            onError: [],
        };
        paymentCallbacksForOffer.onSuccess.push(callback);
        paymentCallbacksForOffer.onError.push(errorCallback);
        this.paymentCallbacks.set(request.offerId, paymentCallbacksForOffer);
        this.paymentRequestReceived.dispatch();
        walletModule.widget.sendMessage(IFrameMessages.PAYMENT_REQUEST, request);
    }
}

export const purchaseModule = new PurchaseModule();
