import {IFrameMessages} from "../../messaging/IFrameMessages";
import {iframeToSdkChannel} from "../../messaging/IframeToSdkChannel";
import {makeSDKToIFrameChannel} from "../../messaging/SdkToIFrameChannel";
import {computeScreenScaleFactor, delayThrottled, getSandboxProperties} from "../../utils/Utils";
import {NodeElement} from "./NodeElement";
import {sdkState} from "../../modules/SDKState";
import {isDeepEqual, getWindowWidth, getWindowHeight} from "../../../blinkpay/UtilsLib";
import {Dispatcher} from "../../../stem-core/src/base/Dispatcher";
import {
    BLINK_PAY_URL,
    IFRAME_HTML_FILE,
    IFRAME_TEST_ID,
    IS_PRODUCTION,
    MERCHANT_STORAGE_KEYS, TEST_MODE,
} from "../../Constants";
import {merchantLocalStorage, merchantSessionStorage} from "../../modules/merchant-state/MerchantStorage";
import {
    getMerchantId,
    getPublicData,
    isMerchantActive,
    onPublicDataLoaded
} from "../../modules/PublicData";
import {loadReferrerUrl} from "../../utils/Referrer";

/** @type {Set<IFrameElement>} */
export const allIFrameElements = new Set();

export const onNewIFrameElementMounted = new Dispatcher();


export class IFrameElement extends NodeElement {
    lastSentIframeParams = null;
    static totalTransitioning = 0;

    // TODO We should consider using IFrameElement only for elements that
    // always have an existing iframe (i.e. widget, inline paywalls)
    // and use another class for elements that are re-using nodes (i.e. modals)
    // That class should act strictly as a controller, for the UI rendered in the Iframe
    constructor(options={}, node=null) {
        super(options, node);
        if (!this.node.src) {
            this.node.src = `${BLINK_PAY_URL}/${IFRAME_HTML_FILE}`;
            this.node.sandbox = getSandboxProperties();
            this.node.frameBorder = 0;
        }
        this.sendMessage = makeSDKToIFrameChannel(this.node, this.appType);
    }

    get appType() {
        return null;
    }

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            nodeType: "iframe",
            testId: IFRAME_TEST_ID.iframeElement,
        };
    }

    updateOptions(options) {
        super.updateOptions(options);
        this.resendIFrameParams();
    }

    // TODO I propose to change this to getBlinkSDKParams
    getIFrameParams() {
        return {
            ...this.options, // TODO @flow2 just sent everything, sure
            forceHideWallet: sdkState.hideWallet,
            preferredEmail: sdkState.preferredEmail,
            allowUserToCoverFees: sdkState.allowUserToCoverFees,
            screen: {
                width: computeScreenScaleFactor() * getWindowWidth(),
                height: computeScreenScaleFactor() * getWindowHeight(),
                isEmulatorScreen: true,
            },
            merchantPageUrl: window.location.href || "",
            merchantPageReferrerUrl: loadReferrerUrl(),
        };
    }

    // TODO
    //  1. remove the (re) from naming
    //  2. rename the function to something more specific, i.e. updateSdkState /updateSdkParams / sendSdkState
    resendIFrameParams() {
        if (!this.node) {
            // Some panel might have "borrowed" this IFrame's node (applies to widget).
            // Make sure to mark the last sent iFrameParams so the next message gets
            // sent no matter what.
            // TODO WAT?
            this.lastSentIframeParams = null;
            return;
        }
        const iframeParams = this.getIFrameParams();
        if (isDeepEqual(iframeParams, this.lastSentIframeParams)) {
            return;
        }
        this.lastSentIframeParams = iframeParams;
        this.sendMessage(IFrameMessages.UPDATE_APP_STATE, {iframeParams});
    }

    addSdkListener(type, callback) {
        this.attachListener(iframeToSdkChannel, type, (payload, sourceIFrame, appType) => {
            if (sourceIFrame === this.node && appType === this.appType) {
                callback(payload);
            }
        });
    }

    getIFrameInitData() {
        return {
            merchantId: getMerchantId(),
            publicData: getPublicData(),
            iframeParams: this.getIFrameParams(),
            merchantToken: merchantLocalStorage.getItem(MERCHANT_STORAGE_KEYS.authToken) || merchantSessionStorage.getItem(MERCHANT_STORAGE_KEYS.authToken),
            appType: this.appType,
        };
    }

    initialize() {
        this.addCleanupJob(onPublicDataLoaded(() => {
            if (!isMerchantActive()) {
                this.destroyNode();
                return;
            }
            this.sendMessage(IFrameMessages.CREATE_IFRAME_APP, {iFrameInitData: this.getIFrameInitData()});

            // Register this IFrame to receive updates from the analytics module when the
            // analytics environment changes.
            allIFrameElements.add(this);
            onNewIFrameElementMounted.dispatch(this);
            this.addCleanupJob(() => allIFrameElements.delete(this));
        }));
    }

    onMount() {
        super.onMount();

        this.initialize();
        this.attachChangeListener(sdkState, () => this.resendIFrameParams());
        this.attachEventListener(window, "resize", delayThrottled(() => this.resendIFrameParams(), 20));

        if (!IS_PRODUCTION || TEST_MODE) {
            this.addSdkListener(IFrameMessages.TRANSITION_CHANGE, ({transitioning}) => {
                // TODO @cleanup Why not use a gray code to "optimize"?
                if (!this.constructor.totalTransitioning && !transitioning) {
                    return;
                }
                this.constructor.totalTransitioning += transitioning ? 1 : -1;
                this.node.setAttribute("transitioning", this.constructor.totalTransitioning > 0);
            });
        }
    }
}
