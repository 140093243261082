import {MERCHANT_STORAGE_KEY_PREFIX} from "../../Constants";

class MerchantStorage {
    /** @param {function():Storage} getStorage */
    constructor(getStorage) {
        this.getStorage = getStorage;
    }

    getItem(key) {
        try {
            let value = this.getStorage().getItem(MERCHANT_STORAGE_KEY_PREFIX + key);
            return JSON.parse(value);
        } catch (e) {
            return null;
        }
    }

    setItem(key, value) {
        try {
            value = JSON.stringify(value);
            this.getStorage().setItem(MERCHANT_STORAGE_KEY_PREFIX + key, value);
        } catch (e) {
        }
    }

    removeItem(key) {
        try {
            this.getStorage().removeItem(MERCHANT_STORAGE_KEY_PREFIX + key);
        } catch (e) {
        }
    }
}

export const merchantLocalStorage = new MerchantStorage(() => window.localStorage);
export const merchantSessionStorage = new MerchantStorage(() => window.sessionStorage);

const memoryStorageImpl = {
    items: new Map(),
    getItem: (key) => memoryStorageImpl.items.get(key),
    setItem: (key, value) => memoryStorageImpl.items.set(key, value),
    removeItem: (key) => memoryStorageImpl.items.delete(key),
};
export const memoryStorage = new MerchantStorage(() => memoryStorageImpl);

export const StorageScope = {
    PERMANENT: "permanent",
    TAB: "tab",
    MEMORY: "memory",
};

export function getStorage(scope) {
    if (scope === StorageScope.PERMANENT) {
        return merchantLocalStorage;
    }
    if (scope === StorageScope.TAB) {
        return merchantSessionStorage;
    }
    return memoryStorage;
}
