import {NodeElement} from "../elements/NodeElement";
import {getWindowWidth, isSmallScreen} from "../../../blinkpay/UtilsLib";
import {WidgetClose} from "./WidgetClose";
import {WidgetButton} from "./WidgetButton";
import {Widget} from "./Widget";
import {MIN_SUPPORTED_WINDOW_WIDTH} from "../../Constants";
import {userDataModule} from "../../modules/UserDataModule";
import {computeScreenScaleFactor, getWidgetRightPosition} from "../../utils/Utils";
import {isMerchantActive, onPublicDataLoaded} from "../../modules/PublicData";


let smallWidthMobileStyleInserted = false;

function getMinSupportedWindowWidthStyle() {
    if (getWindowWidth() >= MIN_SUPPORTED_WINDOW_WIDTH) {
        return {
            width: "100%",
            transform: "",
            height: "100%",
        };
    }

    if (!smallWidthMobileStyleInserted) {
        const styleElement = document.createElement("style");
        styleElement.innerHTML = "body>.blink-sdk-widget-container {max-width: initial !important;}";
        document.head.appendChild(styleElement);
        smallWidthMobileStyleInserted = true;
    }

    return {
        width: `${MIN_SUPPORTED_WINDOW_WIDTH}px`,
        transform: `scale(${1 / computeScreenScaleFactor()})`,
        transformOrigin: "top left",
        height: computeScreenScaleFactor() * 100 + "%",
    };
}


const WidgetContainerStyle = {
    container: {
        position: "fixed",
        pointerEvents: "none",
        transition: "opacity ease .3s",
        display: "flex",
        flexDirection: "column",
        top: 0,
        left: 0,
        opacity: 1,
        caretColor: "transparent",
        outline: "none",
        cursor: "default",
        zIndex: 9999999,
        width: "100%",
        overflow: "initial",
        visibility: "visible",
    },
};

export class WidgetContainer extends NodeElement {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            desktopBottomOffset: 0,
            mobileBottomOffset: 0,
            visible: false,
            showBalance: false,
            expanded: false,
            activePanel: false,
        };
    }

    constructor(...args) {
        super(...args);
        this.widget = new Widget();
        this.widgetClose = new WidgetClose();
        this.widgetButton = new WidgetButton();
        this.node.classList.add("blink-sdk-widget-container");
    }

    getOffset() {
        return {
            positionBottom: 24 + (isSmallScreen() ? this.options.mobileBottomOffset : this.options.desktopBottomOffset),
            positionRight: getWidgetRightPosition() + (this.options.extraRightOffset || 0),
        }
    }

    mountOnBody() {
        const tryMount = () => {
            if (document.body) {
                this.mount(document.body)
                this.widget.mount(this.node);
                this.widgetClose.mount(this.node);
                this.widgetButton.mount(this.node);
                onPublicDataLoaded(() => {
                    if (!isMerchantActive()) {
                        this.destroyNode();
                    }
                });
            } else {
                requestAnimationFrame(tryMount);
            }
        }

        if (document.readyState === "loading") {
            document.addEventListener("DOMContentLoaded", tryMount);
        } else {
            tryMount();
        }
    }

    updateOptions(options) {
        super.updateOptions(options);
        const {expanded, showBalance, visible, activePanel} = this.options;

        this.widget.updateOptions({expanded, activePanel, visible});
        this.widgetClose.updateOptions({expanded, activePanel, visible: visible && userDataModule.isAuthenticated()});
        this.widgetButton.updateOptions({expanded, showBalance, activePanel, visible: visible && userDataModule.isAuthenticated()});
    }

    refreshStyle() {
        super.refreshStyle();
        this.setStyle(WidgetContainerStyle.container);
        this.setStyle(getMinSupportedWindowWidthStyle());

        const positionOptions = this.getOffset();
        this.widget.updateOptions(positionOptions);
        this.widgetClose.updateOptions(positionOptions);
        this.widgetButton.updateOptions(positionOptions);
    }
}
