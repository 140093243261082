import {iframeToSdkChannel} from "../messaging/IframeToSdkChannel";
import {IFrameMessages} from "../messaging/IFrameMessages";
import {isDeepEqual} from "../../blinkpay/UtilsLib";
import {Dispatcher} from "../../stem-core/src/base/Dispatcher";
import {merchantLocalStorage, merchantSessionStorage} from "./merchant-state/MerchantStorage";
import {DONATION_COOLDOWN_PERIOD, EMPTY_USER_DATA, MERCHANT_STORAGE_KEYS} from "../Constants";
import {allIFrameElements} from "../ui/elements/IFrameElement";

function isActiveRecurringPayment(recurringPayment, cooldownPeriod=0) {
    return recurringPayment?.activeUntil
        && Date.now() - (new Date(recurringPayment.activeUntil)).getTime() < cooldownPeriod;
}

function hasActiveDonation(userData) {
    // TODO @Mihai we should also see if there was a recurring donation withing the last DONATION_COOLDOWN_PERIOD as well
    return isActiveRecurringPayment(userData.activeRecurringDonation)
            || isActiveRecurringPayment(userData.latestOneTimeDonation, DONATION_COOLDOWN_PERIOD);
}

// Manages the user authentication info.
export const userDataModule = {
    userPreferencesChange: new Dispatcher(),
    authenticationChange: new Dispatcher(),
    subscriptionChange: new Dispatcher(),

    userData: EMPTY_USER_DATA,

    init() {
        iframeToSdkChannel.addListener(IFrameMessages.UPDATE_SDK_USER_DATA, ({userData}) => {
            const oldUserData = {...this.userData};

            Object.assign(this.userData, userData);

            if (oldUserData.userId !== userData.userId) {
                this.authenticationChange.dispatch();
            }

            if (!isDeepEqual(oldUserData.subscription, userData.subscription)) {
                this.subscriptionChange.dispatch(userData.subscription);
            }

            // Since most journey conditions listen for this, add as last
            if (!isDeepEqual(oldUserData, userData)) {
                this.userPreferencesChange.dispatch();
            }
        });

        iframeToSdkChannel.addListener(IFrameMessages.SET_MERCHANT_TOKEN, ({token}) => this.setMerchantToken(token));
    },

    setMerchantToken(token) {
        if (token) {
            merchantLocalStorage.setItem(MERCHANT_STORAGE_KEYS.authToken, token);
            merchantSessionStorage.setItem(MERCHANT_STORAGE_KEYS.authToken, token);
        } else {
            merchantLocalStorage.removeItem(MERCHANT_STORAGE_KEYS.authToken);
            merchantSessionStorage.removeItem(MERCHANT_STORAGE_KEYS.authToken);
        }
        allIFrameElements.forEach(iFrameElement => {
            iFrameElement.sendMessage(IFrameMessages.UPDATE_MERCHANT_TOKEN, {token});
        });
    },

    isAuthenticated() {
        return this.userData.userId != null;
    },

    isRecurringDonor() {
        return isActiveRecurringPayment(this.userData.activeRecurringDonation);
    },

    isDonor() {
        return hasActiveDonation(this.userData);
    },

    isSubscriber() {
        return isActiveRecurringPayment(this.userData.subscription);
    },

    // DO NOT DELETE, this gets exposed below
    // TODO @cleanup mark with decorator
    isNewsletterSubscriber(newsletterId) {
        newsletterId = newsletterId.newsletterId || newsletterId; // TODO @mustfix temp hack to handle having this from options in journeys
        return this.userData.newsletterIds.includes(newsletterId);
    },

    // Marks all methods that should be exposed in journey/merchant function
    exposeMerchantFunctions() {
        // TODO @cleanup should use a decorator explicitly on these methods
        const methods = {};
        for (const key in this) {
            if (key.startsWith("is")) {
                methods[key] = (...args) => !!this[key](...args); // Ensure a bool is returned
            }
        }

        return methods;
    },
}
