import {NodeElement} from "../elements/NodeElement";
import {
    WALLET_COLLAPSED_VISIBLE_BALANCE_WIDTH,
    WALLET_COLLAPSED_WIDTH
} from "../../../blinkpay/widget/WidgetConstants";
import {IFRAME_TEST_ID, DEFAULT_TRANSITION_DURATION_MS} from "../../Constants";


const WidgetButtonStyle = {
    button: {
        background: "none",
        height: WALLET_COLLAPSED_WIDTH + "px",
        borderRadius: "40px",
        position: "absolute",
        zIndex: 3,
        display: "flex",
        outline: 0,
        cursor: "pointer",
        border: "none",
        "-webkit-user-select": "none",
        "-webkit-tap-highlight-color": "transparent",
        userSelect: "none",
        boxShadow: "none",
        textShadow: "none",
    },
};

export class WidgetButton extends NodeElement {
    pointerEventsTimeout = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: IFRAME_TEST_ID.widgetButton,
            expanded: false,
            positionBottom: 0,
            positionRight: 0,
            activePanel: false,
            visible: false,
        };
    }

    refreshStyle() {
        super.refreshStyle();
        this.setStyle({
            ...WidgetButtonStyle.button,
            width: (this.options.showBalance ? WALLET_COLLAPSED_VISIBLE_BALANCE_WIDTH : WALLET_COLLAPSED_WIDTH) + "px",
            bottom: this.options.positionBottom + "px",
            right: this.options.positionRight + "px",
        });

        clearTimeout(this.pointerEventsTimeout);
        if (this.options.expanded || this.options.activePanel || !this.options.visible) {
            this.setStyle("pointerEvents", "none");
            // remove the focus from the button
            // this hides the unwanted dotted outline on firefox mobile
            this.node.blur();
        } else {
            this.pointerEventsTimeout = this.attachTimeout(() => {
                this.setStyle("pointerEvents", "all");
            }, DEFAULT_TRANSITION_DURATION_MS);
        }
    }
}
