// TODO: Maybe move these to a separate file now?
export const {
    LOG_LEVEL,
    IS_PRODUCTION,
    TEST_MODE,
    BLINK_PAY_URL,
    BLINK_API_URL,
    PUBLIC_DATA_BASE_URL,
    BUILD_VERSION,
    BUILD_TIMESTAMP,
    WS_API_URL,
    GOOGLE_PAY_ENV,
    LINKEDIN_CLIENT_ID,
    GOOGLE_CLIENT_ID,
    FACEBOOK_CLIENT_ID,
    TWITTER_CLIENT_ID,

    SOUTHERN_THING_ALIAS, // TODO @Mihai @cleanup remove whatever is ST specific
    TNR_ALIAS,
    BAFFLER_ALIAS,
} = DEPLOY_CONFIG; // This is replaced at build time (see BundleDescription.js)

export const BLINK_SDK_SETUP_EVENT = "blinkSDKSetup"; // Sent when the SDK starts being initialized, before journey evaluation
export const BLINK_SDK_READY_EVENT = "blinkSDKReady"; // Sent after the SDK is fully initialized and all the journeys were executed

export const IFRAME_HTML_FILE = "iframe.html";
export const IFRAME_APP_TYPE = {
    widget: "widget",
    panel: "panel",
};

export const IFRAME_TEST_ID = {
    widgetCloseButton: "blinkWidgetCloseButton",
    widgetButton: "blinkWidgetButton",
    iframeElement: "blinkIframe",
};

export const PANEL_TYPE = {
    auth: "auth",
    subscribe: "subscribe",
    giftSubscription: "giftSubscription",
    payment: "payment",
    donation: "donation",
    shop: "shop",
    custom: "custom",
    newsletter: "newsletter",
    dashboard: "dashboard",
    empty: "empty", // TODO @flow can we just remove this shit?
};

export const GATE_TYPE = {
    cover: "cover",
    preview: "preview",
    popup: "popup",
    popupGate: "popupGate",
    banner: "banner",
};

export const GATE_COVER_PANEL_POSITION = {
    top: "top",
    center: "center",
    bottom: "bottom",
};

export const PANEL_FRAME = {
    none: "none",
    logo: "logo",
};

// TODO @cleanup make these "replace", beginning, end probably
export const GATE_MOUNT_TYPE = {
    replaceChildren: "replaceChildren",
    firstChild: "firstChild",
    lastChild: "lastChild",
};

export const DEFAULT_PANEL_CONTAINERS = [
    {
        selector: ".blink-container,#blink-container",
        type: PANEL_TYPE.payment,
    },
    {
        selector: ".blink-donation-container,#blink-donation-container",
        type: PANEL_TYPE.donation,
    },
    {
        selector: ".blink-subscription-container,#blink-subscription-container",
        type: PANEL_TYPE.subscribe,
        gateType: GATE_TYPE.preview,
    },
    {
        selector: ".blink-purchase-container,#blink-purchase-container",
        type: PANEL_TYPE.payment,
        gateType: GATE_TYPE.preview,
    },
    {
        selector: ".blink-newsletter-container,#blink-newsletter-container",
        type: PANEL_TYPE.newsletter,
    },
];
export const PANEL_INSERTED_ATTR = "data-blink-panel-inserted";

export const BLINK_ACTION_URL_PARAM = "blinkaction";
export const BLINK_VERSION_URL_PARAM = "blinkversion";

// TODO @branch make Enum
export const USER_JOURNEY_ACTION_TYPE = {
    blinkAction: "blinkAction",
    createPanel: "createPanel",
    editPanel: "editPanel",
    callback: "callback",
    setVariable: "setVariable",
};

// The period that marks a one-time donation and considers it
// TODO this should be configurable per merchant
export const DONATION_COOLDOWN_PERIOD = 90 * 24 * 60 * 60 * 1000; // 90 days

export const DEFAULT_TRANSITION_DURATION_MS = 250; // TODO @theme stop using these, only use from themeProps
export const DEFAULT_TRANSITION = DEFAULT_TRANSITION_DURATION_MS + "ms ease";

export const WIDGET_BACKGROUND = "linear-gradient(60deg, #085B68, #064953)";
export const MIN_SUPPORTED_WINDOW_WIDTH = 375;


export const ANALYTICS_ENDPOINT = BLINK_API_URL + "/e/";
export const ANALYTICS_SUBMIT_DEBOUNCE_MS = 100;
export const ANALYTICS_MAX_QUEUED_MESSAGES = 16;

export const MERCHANT_STORAGE_KEY_PREFIX = "blink-|-";

// The whitelist of keys we may add to the host website's localStorage
// or sessionStorage. The real keys used in the storage will have a
// prefix that almost guarantees uniqueness ("blink-|-").
export const MERCHANT_STORAGE_KEYS = {
    loggerLevel: "Logger.loggerLevel",
    onBoardingBehaviour: "UXManager.onboardingBehaviour",
    merchantSessionId: "Analytics.sessionId",
    merchantLocalId: "Analytics.localId",
    selectedUserJourneys: "UJ-selected",
    pageVisits: "UJ-visits-",
    variables: "variables",
    authToken: "authToken",
    utm: "utm",
    referrer: "referrer",
};

export const UTM_PARAM_KEYS = ["medium", "campaign", "source", "term", "content"];

export const INSUFFICIENT_FUNDS_ERROR_CODE = 1207;

export const ACCESS_AUTHORIZATION_REJECTED_ERROR_CODE = 1911;
export const ACCESS_AUTHORIZATION_REJECTED_ERROR_MESSAGE = "The user refused to grant access to his profile details.";

// TODO @cleanup These match SocialAccountProviderEnum on the backend
export const SOCIAL_APPS = {
    Facebook: "facebook",
    Google: "google",
    Twitter: "twitter",
    LinkedIn: "linkedin",
    Github: "github",
    CDS: "cds",
};

export const FLOW_TYPE = {
    subscription: "subscription",
    donation: "donation",
    newsletter: "newsletter",
};

export const FLOW_CHECKPOINT = {
    complete: "complete",
};

export const EMPTY_USER_DATA = {
    userId: null,
    alwaysShowBalance: null,
    showOnboarding: null,
    subscription: null,
    activeRecurringDonation: null,
    latestOneTimeDonation: null,
    activeNewsletters: [],
    newsletterIds: [],
    metadata: {},
};