import {Condition} from "../engine/Condition.js";
import {merchantVariableModule} from "../../merchant-state/MerchantVariableModule.js";
import {ExprEvaluator} from "../../TemplateEvaluation.js";

// TODO do we actually use this anywhere?
export class ExprCondition extends Condition {
    constructor(...args) {
        super(...args);

        // TODO it's kinda wrong if we only reeval this, but all the other expressions are unchanged
        this.attachListener(merchantVariableModule, this.requestReEval);
    }

    evaluate() {
        const evaluator = new ExprEvaluator();
        const value = evaluator.eval(this.options.value);
        // Cast to a bool, since null means "not able to evaluate"
        return !!value;
    }
}
