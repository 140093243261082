import {merchantLocalStorage} from "../merchant-state/MerchantStorage";
import {MERCHANT_STORAGE_KEYS} from "../../Constants";
import {selectRandomByWeight} from "./engine/JourneySelector";
import {analyticsModule} from "../AnalyticsModule";
import {getMerchantUserJourney} from "../PublicData";
import {isString, toArray} from "../../../stem-core/src/base/Utils.js";
import {evaluateConditionAndRun, evaluateJourneyPrecondition} from "./engine/JourneyConditionEvaluator.js";

// Turns each entry in an entrypoint into the full journey object
function normalizeJourneyEntrypoints(journeyEntrypoints) {
    if (!journeyEntrypoints) {
        return [];
    }
    const normalizedEntrypoints = [];
    for (let entrypoint of journeyEntrypoints) {
        const normalizedEntrypoint = [];
        // Each entrypoint has one or more possible journeys that can be selected
        for (const entrypointBranch of toArray(entrypoint)) {
            if (!entrypointBranch) {
                continue;
            }

            let journey = getMerchantUserJourney(entrypointBranch.alias || entrypointBranch);
            if (journey) {
                if (!isString(entrypointBranch)) {
                    delete entrypointBranch.options; // TODO @cleanup don't support all this, we just want weight and priority
                    journey = {
                        ...journey,
                        ...entrypointBranch
                    }
                }
                normalizedEntrypoint.push(journey);
            } // TODO @logging else say something?
        }
        if (normalizedEntrypoint.length > 0) {
            normalizedEntrypoints.push(normalizedEntrypoint);
        }
    }
    return normalizedEntrypoints;
}

class UserJourneyModule {
    init(journeyEntrypoints) {
        journeyEntrypoints = normalizeJourneyEntrypoints(journeyEntrypoints);

        // We might have previously selected some journeys for this visitor.
        const storedSelectedJourneys = merchantLocalStorage.getItem(MERCHANT_STORAGE_KEYS.selectedUserJourneys);

        const selectedJourneyAliases = new Set(storedSelectedJourneys?.aliases ?? []);

        // Remember all user journeys selected from an entrypoint.
        // This maps journey alias to ID.
        const chosenActiveJourneys = {};

        // Selection for a journey happens after all the previous ones were executed
        for (const entrypoint of journeyEntrypoints) {
            let filteredEntrypoint = entrypoint.filter(evaluateJourneyPrecondition);
            if (filteredEntrypoint.length === 0) {
                continue;
            }

            // In case a journey was already selected in the past, we want to keep it selected.
            // TODO @branch journeys might not be sticky
            for (const journey of filteredEntrypoint) {
                if (selectedJourneyAliases.has(journey.alias)) {
                    filteredEntrypoint = [journey];
                    break;
                }
            }

            const journey = selectRandomByWeight(filteredEntrypoint);

            // Mark it as selected
            selectedJourneyAliases.add(journey.alias);
            // Update all selected journeys in local storage.
            merchantLocalStorage.setItem(MERCHANT_STORAGE_KEYS.selectedUserJourneys, {
                aliases: [...selectedJourneyAliases],
            });

            if (entrypoint.length > 1) {
                chosenActiveJourneys[journey.alias] = journey.id;
            }

            // Update the environment of analytics events to include the active journeys.
            if (Object.keys(chosenActiveJourneys).length) {
                analyticsModule.updateEnvironment({
                    journeys: chosenActiveJourneys,
                });
            }

            evaluateConditionAndRun(journey);
        }
    }
}

export const userJourneyModule = new UserJourneyModule();
