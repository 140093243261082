import {NodeElement} from "./NodeElement";
import {IFrameMessages} from "../../messaging/IFrameMessages";
import {GATE_MOUNT_TYPE} from "../../Constants";

export const GateStyle = {
    gate: {
        pointerEvents: "initial",
        height: "100%",
        display: "flex"
    },
};

export class GateElement extends NodeElement {
    styleSheet = GateStyle;

    constructor(options, panel) {
        super(options);
        this.panel = panel;
    }

    refreshStyle() {
        super.refreshStyle();
        this.setStyle(this.styleSheet.gate);
        Object.assign(this.parentNode.style, this.computeParentStyle());
        this.panel.updateOptions({style: this.getPanelStyle()});
        this.setStyle(this.options.customStyle ?? {});
    }

    getPanelStyle() {
        return {
            maxWidth: "100%",
            minWidth: "100%",
            border: 0,
            height: 0,
        };
    }

    computeParentStyle() {
        return {};
    }

    mount(parentNode) {
        this.initialParentStyle = {};
        for (const key of parentNode.style) {
            this.initialParentStyle[key] = parentNode.style[key];
        }

        this.parentNode = parentNode;
        if (this.options.mountType === GATE_MOUNT_TYPE.replaceChildren) {
            parentNode.innerHTML = "";
            super.mount(parentNode, 0);
        } else if (this.options.mountType === GATE_MOUNT_TYPE.firstChild) {
            super.mount(parentNode, 0);
        } else if (this.options.mountType === GATE_MOUNT_TYPE.lastChild) {
            super.mount(parentNode, parentNode.childNodes.length);
        }

        if (this.options.gateType) {
            this.node.classList.add("blink-" + this.options.gateType);
        }

        this.panel.mount(this.node);
    }

    destroyNode() {
        this.panel.destroyNode();
        const revertedParentStyle = {};
        for (const key of Object.keys(this.computeParentStyle())) {
            revertedParentStyle[key] = "";
        }
        Object.assign(this.parentNode.style, revertedParentStyle, this.initialParentStyle);
        super.destroyNode();
    }

    getInteractiveHandlers() {
        return {
            remove: () => {
                if (!this.node) {
                    console.error("Panel has already been destroyed.");
                    return;
                }
                this.destroyNode();
            },
            updatePanel: panelOptions => {
                if (!this.panel) {
                    console.error("Panel has already been destroyed.");
                    return;
                }
                this.panel.updateOptions(panelOptions);
            }
        }
    }

    onMount() {
        super.onMount();
        this.panel.sizeChange.addListener(() => this.refreshStyle());
        this.panel.addSdkListener(IFrameMessages.DESTROY_PANEL, () => {
            this.destroyNode();
        });

        this.attachEventListener(document, "visibilitychange", () => {
            if (document.visibilityState === "visible") {
                this.refreshStyle();
            }
        });
    }
}
