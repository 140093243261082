import {iframeToSdkChannel} from "../messaging/IframeToSdkChannel";
import {IFrameMessages} from "../messaging/IFrameMessages";
import {userDataModule} from "./UserDataModule";
import {WidgetRoutes} from "../../blinkpay/widget/WidgetRoutes";
import {PANEL_TYPE} from "../Constants";
import {panelModule} from "./PanelModule";
import {walletModule} from "./WalletModule";
import {openWindowWithOpener} from "../../blinkpay/UtilsLib";
import {sdkState} from "./SDKState";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../utils/AnalyticsClient";
import {getMerchantPanel} from "./PublicData";


class UXFlowModule {
    init() {
        // The current merchant's page will be refreshed if no specific URL is provided
        iframeToSdkChannel.addListener(IFrameMessages.REDIRECT_TO_URL, ({url, newTab, logOut}) => {
            if (logOut) {
                userDataModule.setMerchantToken(null);
            }
            openWindowWithOpener(url || window.location.href, newTab ? "_blank" : "_self");
        });

        iframeToSdkChannel.addListener(IFrameMessages.START_FLOW_REQUEST, ({flowType, options}) => this.enterFlow(flowType, options));
    }

    enterFlow(flowType, options) {
        options.type = options.type || flowType;
        options.flowType = flowType;
        const analyticsEventPayload = {
            flowType: options.type,
            journeyId: options.journeyId,
            journeyAlias: options.journeyAlias,
            ...options.metadata,
        };
        const panel = getMerchantPanel(options.panel);
        if (panel) {
            analyticsEventPayload.panelId = panel.id;
            analyticsEventPayload.panelAlias = panel.alias;
        }

        // TODO I think we need to have all these values inside the analytics environment
        dispatchAnalyticsEvent(AnalyticsEventType.FLOW_START, analyticsEventPayload);

        if (!options.editRecurring
            && ((flowType === PANEL_TYPE.subscribe && userDataModule.userData.subscription)
                || (flowType === PANEL_TYPE.donation && userDataModule.isRecurringDonor()))) {
            if (sdkState.hideWallet) {
                // TODO @cleanup @branch fuck this complexity
                panelModule.createPopup({
                    ...options,
                    type: PANEL_TYPE.dashboard,
                });
            } else {
                walletModule.changeRoute(WidgetRoutes.INDEX_EXPANDED);
            }
        } else {
            panelModule.createPopup(options);
        }
    }
}

export const uxFlowModule = new UXFlowModule();
