import {Dispatchable, Dispatcher} from "../../../../stem-core/src/base/Dispatcher";


export class Condition extends Dispatchable {
    /** @type {Condition[]} */
    ops = [];

    // requestReEval asks a reeval for the whole condition tree. Assume to be throttled already.
    constructor(journey, options, requestReEval) {
        super();
        this.journey = journey;
        this.options = options;
        // Wrap in a lambda for each instance of Condition to avoid Stem Dispatcher's error about
        // "Can't re-register for the same callback: ...".
        this.requestReEval = () => requestReEval();
    }

    // Returns true or false if can be evaluated, null if evaluation can't be made so far.
    evaluate() {
        return null;
    }

    cleanup() {
        super.cleanup();
        this.ops.forEach(subCondition => subCondition.cleanup());
    }
}

// TODO @cleanup use this in more places
// Make a simple condition that is bound to this function and reevaluates when watchdog dispatches a change
export function MakeSimpleCondition(func, watchdog) {
    return class SimpleCondition extends Condition {
        constructor(...args) {
            super(...args);

            if (watchdog instanceof Dispatchable) {
                this.attachChangeListener(watchdog, this.requestReEval);
            }

            if (watchdog instanceof Dispatcher) {
                this.attachListener(watchdog, this.requestReEval);
            }
        }

        evaluate() {
            return func(this.options, this.journey, this);
        }
    }
}
