import {isSmallScreen} from "../../../blinkpay/UtilsLib";

const PULSE_MIN_SIZE = 60;
const PULSE_MAX_SIZE = 230;
const PULSE_BACKGROUND_COLOR = "#4897a3";
const FIRST_PULSE_START_DELAY = 0;
const FIRST_PULSE_DURATION = 1000;
const SECOND_PULSE_START_DELAY = 250;
const SECOND_PULSE_DURATION = 1250;

// TODO: Maybe use css animations at some point?
function createPulseElement(targetNode, startDelay, duration) {
    const getStyle = progress => {
        const baseOpacity = isSmallScreen() ? 0.4 : 1;
        const size = PULSE_MIN_SIZE + progress * (PULSE_MAX_SIZE - PULSE_MIN_SIZE);
        const {left, top, width, height} = targetNode.getBoundingClientRect();
        const x = left + width / 2 - size / 2;
        const y = top + height / 2 - size / 2;

        return {
            position: "fixed",
            left: x + "px",
            top: y + "px",
            height: size + "px",
            width: size + "px",
            opacity: baseOpacity * (1 - progress),
            zIndex: 1,
            borderRadius: "100%",
            backgroundColor: PULSE_BACKGROUND_COLOR,
            cursor: "pointer",
            pointerEvents: "none",
        };
    };

    const animate = () => {
        const progress = (Date.now() - startTimestamp) / duration;
        if (!document.body.contains(targetNode) || progress > 1) {
            element.remove();
            return;
        }
        Object.assign(element.style, getStyle(progress));
        requestAnimationFrame(animate);
    };

    const element = document.createElement("div");
    document.body.appendChild(element);

    let startTimestamp;
    setTimeout(() => {
        startTimestamp = Date.now();
        requestAnimationFrame(animate);
    }, startDelay);

    return element;
}

export function createPulseAnimation(targetNode) {
    const firstPulseElement = createPulseElement(targetNode, FIRST_PULSE_START_DELAY, FIRST_PULSE_DURATION);
    const secondPulseElement = createPulseElement(targetNode, SECOND_PULSE_START_DELAY, SECOND_PULSE_DURATION);
    return {
        remove() {
            firstPulseElement.remove();
            secondPulseElement.remove();
        },
    };
}
