import {MakeSimpleCondition} from "../engine/Condition";
import {merchantVariableModule} from "../../merchant-state/MerchantVariableModule";


function verifyVariable(options) {
    const value = merchantVariableModule.get(options.key);
    return options.hasOwnProperty("equal") ? (value === options.equal) : value;
}

export const VariableCondition = MakeSimpleCondition(verifyVariable, merchantVariableModule);
