import {Condition} from "../engine/Condition";

export function getVisualViewportHeight() {
    return window.visualViewport?.height ?? window.innerHeight;
}

export function getPageHeight() {
    // Huh, browser people really outdid themselves with this level of
    // inconsistency.
    return Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight,
    );
}

/** @property {{fraction: number|undefined, percent: number|undefined, amount: number|undefined}} options */
export class PageScrollCondition extends Condition {
    constructor(...args) {
        super(...args);

        this.attachEventListener(window, "scroll", this.requestReEval);
    }

    evaluate() {
        // VisualViewport is a measurement that also takes into account any
        // pinch-zoom (for example on Mac OS trackpad or Android). It is not
        // supported by older browsers, so default to more widely supported,
        // yet less accurate, measurements such as window.scrollY.
        // https://developer.mozilla.org/en-US/docs/Web/API/VisualViewport
        const viewportTop = window.visualViewport?.pageTop ?? window.scrollY;
        const viewportHeight = getVisualViewportHeight();

        // Huh, browser people really outdid themselves with this level of
        // inconsistency.
        const pageHeight = getPageHeight();

        let result = true;
        if (this.options.amount != null) {
            result &&= (viewportTop >= this.options.amount);
        }
        if (this.options.fraction != null || this.options.percent != null) {
            const fraction = this.options.fraction ?? this.options.percent / 100;
            const scrollableHeight = pageHeight - viewportHeight;
            result &&= (viewportTop / scrollableHeight >= fraction);
        }
        return result;
    }
}
