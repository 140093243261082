import {memoryStorage, merchantLocalStorage, merchantSessionStorage, StorageScope} from "./MerchantStorage";
import {MERCHANT_STORAGE_KEYS} from "../../Constants";
import {Dispatcher} from "../../../stem-core/src/base/Dispatcher";
import {merchantBuiltinVariables} from "./Builtins";

class MerchantVariableModule extends Dispatcher {
    memory = memoryStorage.getItem(MERCHANT_STORAGE_KEYS.variables) || {};
    tab = merchantSessionStorage.getItem(MERCHANT_STORAGE_KEYS.variables) || {};
    permanent = merchantLocalStorage.getItem(MERCHANT_STORAGE_KEYS.variables) || {};

    add(variables) {
        Object.assign(merchantBuiltinVariables, variables);
    }

    has(key) {
        return this.memory.hasOwnProperty(key)
            || this.tab.hasOwnProperty(key)
            || this.permanent.hasOwnProperty(key)
            || merchantBuiltinVariables.hasOwnProperty(key);
    }

    get(key) {
        if (this.memory.hasOwnProperty(key)) {
            return this.memory[key];
        }
        if (this.tab.hasOwnProperty(key)) {
            return this.tab[key];
        }
        if (this.permanent.hasOwnProperty(key)) {
            return this.permanent[key];
        }
        return merchantBuiltinVariables[key];
    }

    set(key, value, scope = StorageScope.MEMORY) {
        if (scope === StorageScope.PERMANENT) {
            this.permanent[key] = value;
        } else {
            delete this.permanent[key];
            if (scope === StorageScope.TAB) {
                this.tab[key] = value;
            } else {
                delete this.tab[key];
                this.memory[key] = value;
            }
        }
        this.store();
    }

    delete(key) {
        delete this.memory[key];
        delete this.tab[key];
        delete this.permanent[key];
        this.store();
    }

    all() {
        return {
            ...merchantBuiltinVariables,
            ...this.permanent,
            ...this.tab,
            ...this.memory,
        };
    }

    store() {
        merchantSessionStorage.setItem(MERCHANT_STORAGE_KEYS.variables, this.tab);
        merchantLocalStorage.setItem(MERCHANT_STORAGE_KEYS.variables, this.permanent);
        memoryStorage.setItem(MERCHANT_STORAGE_KEYS.variables, this.memory);
        this.dispatch();
    }
}

export const merchantVariableModule = new MerchantVariableModule();
