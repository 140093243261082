export {
    IS_PRODUCTION,
    TEST_MODE,
    BLINK_PAY_URL,
    BUILD_VERSION,
    BUILD_TIMESTAMP,
    WS_API_URL,
    GOOGLE_PAY_ENV,
    SOUTHERN_THING_ALIAS,
} from "../blink-sdk/Constants";

// TODO cleanup and move some of these inside themeProps

export const BUTTON_TYPE = {
    PRIMARY: 0,
    SECONDARY: 1,
};

export const CONFIRMATION_CODE_TYPES = {
    LONG: "long",
    SHORT: "short",
};

export const DEFAULT_CONFIRMATION_CODE_TYPE = CONFIRMATION_CODE_TYPES.SHORT;

export const APP_NAME = "user_dashboard";

export const STRIPE_SRC = "https://js.stripe.com/v3/";

export const WEBSITE_MOBILE_SCREEN_MIN_WIDTH_SUPPORTED = 375;

export const MOBILE_DEVICE_SCREEN_WIDTH_LIMIT = 450; // TODO: this is a random number Mihai picked for CSAcademy, should be different

export const SMALL_SCREEN_WIDTH_LIMIT = 650;

export const ADD_FUNDS_DISABLE_TIMEOUT = 5000;

export const DEFAULT_DATE_FORMAT = "DD MMM YYYY"; // TODO @cleanup use a default date formatter whenever this is explicitly used

export const MISSING_INFO = "-";

export const SUPPORT_EMAIL_ADDRESS = "support@blink.net";

export const TOP_UP_VALUES = [5, 10, 20, 50];

export const LOGIN_URL = "login";
export const LOGIN_WITH_EMAIL_CODE_URL = "login-with-email-code";

export const MOBILE_NAV_HEIGHT = 56;

// Max length for any inline credit card error
export const CARD_ERROR_MAX_LENGTH = 60;

export const IOS_BOTTOM_OFFSET = 104;

export const DONATIONS_URL = "donations";

export const SUBSCRIPTIONS_URL = "subscriptions";
