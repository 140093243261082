import {Condition} from "../engine/Condition";
import {normalizePath, onCurrentAndFuturePageAccess} from "../../../utils/PageAccessHelpers.js";
import {testPath} from "./PathCondition";
import {updatePageVisit} from "../../../utils/PageAccessHelpers.js";
import {merchantVariableModule} from "../../merchant-state/MerchantVariableModule.js";

const PAGE_VISIT_ACTIVATION_TYPE = {
    atLeast: "atLeast", // default.
    exact: "exact",
    atMost: "atMost",
};

// TODO @branch we also want some rules to just always trigger, this is incredibly stupid
function shouldActivate(numVisits, expectedNumVisits, activationType) {
    if (activationType === PAGE_VISIT_ACTIVATION_TYPE.exact) {
        return numVisits === expectedNumVisits;
    }
    if (activationType === PAGE_VISIT_ACTIVATION_TYPE.atMost) {
        return numVisits <= expectedNumVisits;
    }
    return numVisits >= expectedNumVisits;
}

function shouldSaveResult(result, activationType) {
    if (activationType === PAGE_VISIT_ACTIVATION_TYPE.exact) {
        return true;
    }
    if (activationType === PAGE_VISIT_ACTIVATION_TYPE.atMost) {
        return result === true;
    }
    return result === false;
}

/** @property {{
 *      numPages: number|undefined,
 *      scope: string|undefined,
 *      pathInclude: string|string[]|undefined,
 *      pathExclude: string|string[]|undefined,
 *      pathOneOf: string|string[]|undefined,
 *      timeWindow: number|undefined,
 *      filterFunction: string|Array|undefined,
 *      activationType: string|undefined,
 *  }} options */
export class NthPageVisitCondition extends Condition {
    constructor(...args) {
        super(...args);

        const {
            numPages = 1,
            activationType = PAGE_VISIT_ACTIVATION_TYPE.atLeast,
            scope = this.journey.alias,
            altScope,
            timeWindow,
        } = this.options;

        onCurrentAndFuturePageAccess((url) => {
            const currentPath = normalizePath(url);
            const pathMatches = this.testPath(currentPath);

            // TODO @branch implement cleaner
            const [currentVisit, pageVisitsMap] = updatePageVisit(url, {
                [scope]: pathMatches,
            });

            const oldestAllowedTimestamp = timeWindow ? Date.now() - timeWindow * 1000 : -Infinity;
            const numCountedVisits = [...pageVisitsMap.values()].filter(
                visit => (visit.lastVisit >= oldestAllowedTimestamp && visit[scope])
            ).length;

            // Store the counter variable, and also save in a possibly alternate counter (used for counter before and after reg, that should overwrite another variable)
            // TODO the variable system is incredibly complex, wow
            merchantVariableModule.set(scope + ".count", numCountedVisits);
            if (altScope) {
                merchantVariableModule.set(altScope + ".count", numCountedVisits);
            }

            const prevResult = this.cachedResult;
            this.cachedResult = pathMatches && shouldActivate(numCountedVisits, numPages, activationType);
            if (shouldSaveResult(this.cachedResult, activationType)) {
                updatePageVisit(url, {
                    ["numVisits." + scope]: numCountedVisits,
                });
            }

            if (prevResult != null) {
                // TODO @branch not nice!
                this.requestReEval();
            }
        });
    }

    testPath(path) {
        return testPath(path,
            this.options.pathInclude,
            this.options.pathExclude,
            this.options.pathOneOf,
            this.options.filterFunction);
    }

    evaluate() {
        return this.cachedResult;
    }
}
